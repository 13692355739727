import React, { useEffect } from 'react';

const DescriptionCard = (props) => {
    useEffect(() => {
    }, [props.element.description])

    const onChange = (event) => {
        let { value } = event.target
        props.onChange("description", value, props.index)
    }

    let { element } = props
    return (
        <React.Fragment>
            <div className='description-card-outer'>
                <h3>Description</h3>
                <textarea disabled={element.is_expired} value={(element.description) ? element.description : ""} name="description" onChange={(e) => onChange(e)} rows={5} placeholder='' ></textarea>
                <div className="btn-save-remarks">
                    <button disabled={element.is_expired || element.modify_status === 0} onClick={(e) => { props.onUpdate(element) }} type="button" className="btn-primary">{element.id ? "Update" : "Add"}</button>
                    <button disabled={element.is_expired} onClick={(e) => { props.onCancel(element) }} type="button" className="btn-line m-sm-l">Cancel</button>
                </div>
            </div>

        </React.Fragment>
    )
}
export default DescriptionCard;