/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { API_URL, APIV } from './../config/constant';
import secureStorage from './../config/encrypt';
import { toast } from 'react-toastify';
import ls, { get, set } from "local-storage";

const Axios = axios.create();
Axios.interceptors.response.use(
    (response) => {
        if (response.data && response.data.status !== 200) {
            console.error(`${response.data.status} - ${response.data.message}`, response.data)
            // if(response.data.status == 400
            //     window.commonErrorToast(response.data.message)
            //     toast.error(`${response.data.status} - ${response.data.message}`);
        }
        ls.set("is_redirect", 1)
        return response;
    },
    (error) => {
        // handle error
        if (error.response) {
            console.error(`${error.response.status} - ${error.response.statusText}`, error.response)
            if (error.response.status == 503) {
                // toast.error(`${error.response.status} - ${error.response.statusText}`);
            } else if (error.response.status == 401) {
                // if(error.response.data.errors == "TokenEmptyInCache") 
                //toast.error(`${error.response.data.status} - ${error.response.data.message}`);
                let is_redirect = ls.get("is_redirect")
                if(is_redirect !== 0){
                    window.location.href = '/logout';
                }
            }
        }

        return error.response;
    });

const errorHandler = error => {
    return Promise.reject(error);
};


window.commonErrorToast = function (error) {
    if (typeof error == "string") toast.error(error);
    else if (error.message) toast.error("Helllo", error.message);
}
export default {
    getMasters(list) {
        return this.get("/core/commonservice/master", { master: list });
    },
    getToken() {
        let fetchHeader = (secureStorage.getItem('loginUserInfo'))
            ? secureStorage.getItem('loginUserInfo')
            : {};
           // console.log('$$$$$$$$$$$$$$',fetchHeader.token);
        return (fetchHeader && fetchHeader.token)
            ? fetchHeader.token : null;
    },
    delete(URL, body, headers) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.delete(`${API_URL.GETWAY_API}${URL}`, body, { headers })
    },
    post(URL, body, headers) {
        headers = headers || {};       
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        //headers['apikey'] = API_URL.API_KEY;        
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.post(`${API_URL.GETWAY_API}${URL}`, body, { headers,withCredentials:false })
    },
    put(URL, body, headers) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.put(`${API_URL.GETWAY_API}${URL}`, body, { headers })
    },
    get(URL, query, headers) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        //headers['apikey'] = API_URL.API_KEY;
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.get(`${API_URL.GETWAY_API}${URL}`,
            { params: query, headers })
    },
    uploadFilesToS3(postdata, headers) {
        headers = headers || {};
        return axios.post(
            `${API_URL.COMMON_GATEWAY_API}core/commonservice/docs_upload`,
            postdata,
            { headers }
        );
    },
    getLocalityList(params, headers) {
        headers = headers || {};
        return axios.get(
            `${API_URL.COMMON_GATEWAY_API}core/commonservice/locality_list?city=`+params,
            { headers }
        );
    },   
    download(URL, body, responseType,  headers) {
        headers = headers || {};       
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        //headers['apikey'] = API_URL.API_KEY;        
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.post(`${API_URL.GETWAY_API}${URL}`,  body, {responseType, headers,withCredentials:false })
    },
}