import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Tooltip from 'react-tooltip-lite';
import { MasterService } from '../../services';
import InputField from '../common/Input';
const SalesMappedUser = (props) => {
    const [tipOpen, setTipOpen] = useState(false);
    const [tipOpenId, setTipOpenId] = useState(0);
    const [search_text, setSearchText] = useState('')
    const [assigned_executive_data, setAssignedExecutiveData] = useState(null)
    const [unassigned_executive_data, setUnassignedExecutiveData] = useState(null)

    useEffect(() => {
        setAssignedExecutiveData(props.assigned_executive)
        setUnassignedExecutiveData(props.unAssigned_Executive)
        setTipOpenId('')
    }, [props.assigned_executive, props.unAssigned_Executive])

    useEffect(() => {
        setSearchText("")
    }, [props.search_reset])

    const toggleTip = (user_id, state) => {
        if (state === 'close') {
            user_id = ''
        }
        setTipOpen(!tipOpen)
        setTipOpenId(user_id)
    }

    const handleInputChange = async (target_name, type, event) => {
        let { value } = event.target
        if (target_name === "search_text") {
            setSearchText(value)
            if (value.length > 2) {
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, search_text: value }
                await props.handleUnAssignedExecutive(params, assigned_executive_data)
            }
            if (value.length == 0) {
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, search_text: "" }
                await props.handleUnAssignedExecutive(params, assigned_executive_data)
            }
        }
    }

    const handleClick = (element, index, type) => {
        let temp_unassigned_executive_data = [...unassigned_executive_data]
        let temp_assigned_executive_data = [...assigned_executive_data]
        if (type === 'unassigned') {
            temp_unassigned_executive_data = temp_unassigned_executive_data.filter((e) => e.user_id != element.user_id)
            temp_assigned_executive_data.unshift(element)
            /* if(temp_assigned_executive_data.length < 1){
            } else {
                toast.error("Please unmap the assigned executive first")
            } */
        }
        if (type === 'assigned') {
            temp_assigned_executive_data = temp_assigned_executive_data.filter((e) => e.user_id != element.user_id)
            temp_unassigned_executive_data.unshift(element)

        }
        setAssignedExecutiveData(temp_assigned_executive_data)
        setUnassignedExecutiveData(temp_unassigned_executive_data)
    }

    const handleSubmit = async () => {
        let assigned_user_id = assigned_executive_data.map(({ user_id }) => user_id)
        props.updateAssignExecutive(assigned_user_id)
        setSearchText("")
    }

    return (
        <React.Fragment>
            <div className='sales-users-left-panel'>
                <h3>Sales Users</h3>
                <div className='sales-mapped-bg'>
                    <fieldset class="search-bx">
                        <div class="material">
                            <InputField type="text" name="search_text" id="search_text" placeholder="Search Sales Officer" value={search_text} onChange={handleInputChange.bind(null, 'search_text', 'text')} />
                            <i class="ic-search"></i>
                        </div>
                    </fieldset>
                    <div className='sales-user-list'>
                        <ul>
                            {unassigned_executive_data && unassigned_executive_data.length > 0 && unassigned_executive_data.map((element, index) => (
                                <li key={index}>
                                    <label>{element.name + " - "+ element.user_id +(element.role_name ? " (" + element.role_name + ")" : "") + (element.reporting_manager_name ? (" ," + element.reporting_manager_name) : "") + (element.reporting_manager_role_name ? " (" + element.reporting_manager_role_name + ")" : "")}</label>
                                    {element.dealercount > 0 &&
                                        <span className='mapped_user_toggle'>{element.dealercount + " Mapped Dealers"}
                                        <i onClick={() => toggleTip(element.user_id, 'open')} className='ic-info-icon1 m-sm-l'></i>
                                            <span  title="" className="tooltio-ic">
                                                
                                            </span>
                                            
                                            <Tooltip
                                                content={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">
                                                            <span className="controlled-example_close-button" onClick={() => toggleTip(element.user_id, 'close')}><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                {element.assigned_dealer_data?.length && element.assigned_dealer_data.map((dealer_element, dealer_index) => (
                                                                    <li key={dealer_index}>{dealer_element.dealer_name+" (GCD-"+dealer_element.id+")"}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                                isOpen={(element.user_id === tipOpenId) && tipOpen ? true : false}
                                                tagName="span"
                                                direction="down"
                                                className="tootip-outer"
                                                forceDirection
                                            >
                                            </Tooltip>
                                        </span>
                                    }
                                    <i onClick={(e) => handleClick(element, index, 'unassigned')} className='ic-arrow_forward listclose-ic'></i>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className='mapped-users-right-panel'>
                <h3>Mapped Sales Users</h3>
                <div className='sales-mapped-bg'>

                    <div className='sales-user-list'>
                        <ul>
                            {assigned_executive_data && assigned_executive_data.length > 0 && assigned_executive_data.map((element, index) => (
                                <li key={index}>
                                    <label >{element.name  + " - "+ element.user_id + (element.role_name ? " (" + element.role_name + ")" : "") + (element.reporting_manager_name ? (" ," + element.reporting_manager_name) : "") + (element.reporting_manager_role_name ? " (" + element.reporting_manager_role_name + ")" : "")}</label>
                                    {element.assigned_dealer_data.length > 0 &&
                                        <span className='mapped_user_toggle'>{element.assigned_dealer_data.length + " Mapped Dealers"}
                                        <i onClick={() => toggleTip(element.user_id, 'open')} className='ic-info-icon1 m-sm-l'></i>
                                            <span  title="" className="tooltio-ic">
                                                
                                            </span>
                                            <Tooltip
                                                content={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">
                                                            <span className="controlled-example_close-button" onClick={() => toggleTip(element.user_id, 'close')}><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                {element.assigned_dealer_data?.length && element.assigned_dealer_data.map((dealer_element, dealer_index) => (
                                                                    <li key={dealer_index}>{dealer_element.dealer_name+" (GCD-"+dealer_element.id+")"}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                                isOpen={(element.user_id === tipOpenId) && tipOpen ? true : false}
                                                tagName="span"
                                                direction="down"
                                                className="tootip-outer"
                                                forceDirection
                                            >
                                            </Tooltip>
                                        </span>

                                    }
                                    <i onClick={(e) => handleClick(element, index, 'assigned')} className='ic-clearclose listclose-ic'></i>
                                </li>
                            ))}

                        </ul>
                        <div onClick={() => handleSubmit()} className='btn-submit'>
                            <button className='btn-primary'>  Submit </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mapped-users-right-panel' style={{ "display": 'none' }}>
                <h3>Mapped Sales Users</h3>
                <div className='sales-mapped-bg'>

                    <div className='sales-user-list no-data-txt'>
                        <ul>
                            <li>Move Sales Officer here</li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
    // }
}
export default SalesMappedUser;