import secureStorage from "./encrypt";
import _ from "lodash";
export const API_URL = {
	GETWAY_API: process.env.REACT_APP_GETWAY_API,
	COMMON_GATEWAY_API: process.env.REACT_APP_GETWAY_API || "https://phbeta-api.oto.com/",
	API_KEY: process.env.REACT_APP_API_KEY || "sfaph-65697064-1f1f-4ee9-bc4a-9aff25221f13",
};

export const SETTING = {
	CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
	RESEND_OTP_TIME_IN_SECOND: 30,
};
export const NAME_VALIDATION = /^[A-Za-z\s]+$/;

export const ALPHA_NUMERIC_VALIDATION = /^[a-zA-Z0-9 ]+$/;

export const ACCOUNT_NO_VALIDATION = /^\d{10,16}$/;

export const SPOUSE_OFFICE_ADDRESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,70}$/;

export const SPOUSE_BUSINESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,20}$/;

export const EMERGENCY_CONTACT_NAME_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,50}$/;

export const EMERGENCY_CONTACT_ADDRESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,70}$/;

export const MOBILE_VLIDATION_RULE = /^\+\d{1,3}-\d{9,10}$/;

export const PASSWORD_VALIDATION=/^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/;

export const EMAIL_VALIDATION = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export const MARGIN_FOR_OTO_MAX_VALIDATION = 1000000;

export const IS_USER_ACCESS_PAGE = (controller, action) => {
	let isUserHasRights = false, isEditAccess = false;
	const userAccessFeatures = secureStorage.getItem("userAccess");
	if (userAccessFeatures && userAccessFeatures.length) {
		let isKeyExist = _.findKey(userAccessFeatures, function (v) {
			if (controller && v.controller && action && v.action) {
				return (
					v.controller.toLowerCase() === controller.toLowerCase() &&
					v.action.toLowerCase() === action.toLowerCase()
				);
			}
		});
		isUserHasRights = isKeyExist ? true : false;
		isEditAccess = isKeyExist ? ((Number(userAccessFeatures[isKeyExist].access_type) === 1) ? true : false) : false
	}

	return {
		is_access_page: isUserHasRights,
		is_edit_access: isEditAccess
	};
};

export const APIV = 2;

export const FINANCIER_PRODUCT_TYPE_OPTIONS = [
	{ label: "UCF", value: "UCF" },
	{ label: "UCRF", value: "UCRF" },
	{ label: "UBRF", value: "UBRF" },
	{ label: "INSURANCE", value: "INSURANCE" },
];
export const TAGGING_ROLES = [53, 54, 57];

export const Zone = [
	{ id: 1, value: 'Thailand', label: 'Thailand' }
];

export const Business = [
	{ id: 1, value: 'OTO TH', label: 'OTO TH' }
];

export const Vertical = [
	{ id: 1, value: 'Used Car', label: 'Used Car' }
];
export const defaultBusiness = 1
export const defaultVertical = 1

export const defaultZone = 1

export const VIRTUAL_ADMIN = "Virtual Admin"

export const EmploymentType = [
	{ value: '0', label: 'Off Roll' },
	{ value: '1', label: 'On Roll' },
];

export const BusinessLine = [
	{ value: 'UCF', label: 'Used Car Finance' },
	{ value: 'UCRF', label: 'Used Car Refinance' },
	{ value: 'UBRF', label: 'Used Bike Refinance' },
	{ value: 'insurance', label: 'Insurance' }
];

export const validationForFields = {
	"incentive_percentage": [52],
	"current_occupation": [53],
	"referral_name": [53],
	"company_name": [53],
	"designation": [53],
	"tagging": [6],
	"source": [53, 54, 57],
	"is_tax_booster_enable": [56],
	// "tin_id": [1,2,3,4,5,6],
	// "aro_so_assigned": [1,2,3,4,5,6]
}
export const invalidNumberValues = ["-", "+", "e", "."];

export const imageWidth = 360;
export const minDateYear = 1970;

// export const Role = {
// 	"RBH": 51,
// 	"BM": 52,
// 	"Agent": 53,
// 	"BRO": 54,
// 	"Virtual Admin": 55,
// 	"AM": 56,
// 	"ARO TEST": 57,
// 	"ARO": 57,
// 	"ZM": 58,
// 	"ARCO": 59,
// 	"VP": 50
// }

export const Role = {
	"Virtual Admin": 1,
	"President": 2,
	"Sales Head": 3,
	"Territory Head": 4,
	"Sales Manager": 5,
	"Sales Officer": 6
}

// export const RoleId = {
// 	"51": "RBH",
// 	"52": "BM",
// 	"53": "Agent",
// 	"54": "BRO",
// 	"55": "Virtual Admin",
// 	"56": "AM",
// 	"57": "ARO",
// 	"58": "ZM",
// 	"59": "ARCO",
// 	"50": "VP"
// }

export const RoleId = {
	"1": "Virtual Admin",
	"2": "President",
	"3": "Sales Head",
	"4": "Territory Head",
	"5": "Sales Manager",
	"6": "Sales Officer"
}

export const BasicDetailConfig = {
	"current_occupation": [
		{
			"value": "freelancer_broker",
			"label": "Freelancer/Broker"
		},
		{
			"value": "sales_marketing_dealer",
			"label": "Sales/Marketing dealer"
		},
		{
			"value": "unemployed",
			"label": "Unemployed"
		},
		{
			"value": "cmo_leasing",
			"label": "CMO leasing"
		},
		{
			"value": "fulltime_private_employee",
			"label": "Full time private employee"
		},
		{
			"value": "owner_dealer",
			"label": "Owner dealer"
		},
		{
			"value": "umkm_business",
			"label": "UMKM Business"
		},
		{
			"value": "parttime_private_employee",
			"label": "Part time private employee"
		},
		{
			"value": "bm_leasing",
			"label": "BM leasing"
		},
		{
			"value": "makelar",
			"label": "Makelar"
		},
		{
			"value": "marketing",
			"label": "Marketing"
		},
		{
			"value": "sales_dealer",
			"label": "Sales Dealer"
		},
		{
			"value": "other",
			"label": "Other"
		}
	],
	"designation": [
		{
			"value": "freelance",
			"label": "Freelance"
		},
		{
			"value": "employee",
			"label": "Employee"
		},
		{
			"value": "property_agent",
			"label": "Property Agent"
		},
		{
			"value": "owner",
			"label": "Owner"
		},
		{
			"value": "unemployed",
			"label": "Unemployed"
		},
		{
			"value": "cmo",
			"label": "CMO"
		},
		{
			"value": "collector",
			"label": "Collector"
		},
		{
			"value": "ex_bro",
			"label": "Ex-BRO"
		},
		{
			"value": "mh",
			"label": "MH"
		},
		{
			"value": "bm",
			"label": "BM"
		},
		{
			"value": "dealer_owner",
			"label": "Dealer Owner"
		},
		{
			"value": "am",
			"label": "AM"
		},
		{
			"value": "consultant",
			"label": "Consultant"
		},
		{
			"value": "work_in_dealer",
			"label": "Work in dealer"
		},
		{
			"value": "driver",
			"label": "Driver"
		},
		{
			"value": "car_agent",
			"label": "Car Agent"
		},
		{
			"value": "bro",
			"label": "BRO"
		},
		{
			"value": "others",
			"label": "Others"
		}
	],
	"source": [
		{
			"value": "Website",
			"label": "Website"
		},
		{
			"value": "BM",
			"label": "BM"
		},
		{
			"value": "referral_agent",
			"label": "Referral Agent"
		},
		{
			"value": "Others",
			"label": "Others"
		}
	],
	"document": [
		{
			"doc_id": "1",
			"doc_title": "Selfie with ID",
			"doc_min": "1",
			"doc_max": "1",
			"doc_required": "1",
			"doc_logo_url": "https://otobackendbeta.storage.googleapis.com/SFA/12/1648618247543.png",
			"doc_bg_color": "#BFF8FC"
		},
		{
			"doc_id": "2",
			"doc_title": "ID",
			"doc_min": "1",
			"doc_max": "1",
			"doc_required": "1",
			"doc_logo_url": "https://otobackendbeta.storage.googleapis.com/SFA/12/1648618247544.png",
			"doc_bg_color": "#FFF1D6"
		},
		{
			"doc_id": "3",
			"doc_title": "Bank Statement",
			"doc_min": "1",
			"doc_max": "1",
			"doc_required": "1",
			"doc_logo_url": "https://otobackendbeta.storage.googleapis.com/SFA/12/1648618247545.png",
			"doc_bg_color": "#D8EAFF"
		},
		{
			"doc_id": "4",
			"doc_title": "NPWP",
			"doc_min": "1",
			"doc_max": "1",
			"doc_required": "1",
			"doc_logo_url": "https://otobackendbeta.storage.googleapis.com/SFA/12/1648618247545.png",
			"doc_bg_color": "#C5F8EB"
		}
	],
	"tagging_roles": {
		"Agent": [
			{
				"value": "regular",
				"label": "Regular"
			},
			{
				"value": "dsoo",
				"label": "DSOO"
			},
			{
				"value": "dse",
				"label": "DSE"
			}
		],
		"ARO": [
			{
				"value": "sr_aro",
				"label": "Sr. ARO"
			},
			{
				"value": "aro",
				"label": "ARO"
			},
			{
				"value": "jr_aro",
				"label": "Jr. ARO"
			}
		],
		"BRO": [
			{
				"value": "sr_bro",
				"label": "Sr. BRO"
			},
			{
				"value": "bro",
				"label": "BRO"
			},
			{
				"value": "jr_bro",
				"label": "Jr. BRO"
			}
		]
	},
	"business_line": [
		{
			"value": "UCF",
			"label": "Used Car Finance"
		},
		{
			"value": "UCRF",
			"label": "Used Car Refinance"
		},
		{
			"value": "UBRF",
			"label": "Used Bike Refinance"
		},
		{
			"value": "insurance",
			"label": "Insurance"
		}
	],
	"hiring_source": [
		{
			"value": "existing",
			"label": "Existing"
		},
		{
			"value": "new",
			"label": "New"
		}
	]
}

export const defaultPassword = "Default@123"

export const VP_ID = 1

export const PAGINATION = {
	PAGE_SIZE: 50
}

// export const MANAGER_ROLES = [
// 	{ "name": "ARO", "id": 57, "rank": 8 },
// 	{ "name": "ARCO", "id": 59, "rank": 7 },
// 	{ "name": "BM", "id": 52, "rank": 6 },
// 	{ "name": "AM", "id": 56, "rank": 5 },
// 	{ "name": "ZM", "id": 58, "rank": 4 },
// 	{ "name": "RBH", "id": 51, "rank": 3 },
// 	{ "name": "VP", "id": 50, "rank": 2 },
// 	{ "name": "Virtual Admin", "id": 55, "rank": 1 },
// ]

export const MANAGER_ROLES = [
	{ "name": "Sales Officer", "id": 6, "rank": 6 },
	{ "name": "Sales Manager", "id": 5, "rank": 5 },
	{ "name": "Territory Head", "id": 4, "rank": 4 },
	{ "name": "Sales Head", "id": 3, "rank": 3 },
	{ "name": "President", "id": 2, "rank": 2 },
	{ "name": "Virtual Admin", "id": 1, "rank": 1 },
]
export const SFA_URL = process.env.REACT_APP_SFA_URL || "https://sfanew.oto.com/";
export const STATUS= [
	{id : 1, label : 'Active', value : 'active'},
	{id : 2, label : 'Inactive', value : 'inactive'},
]
export const FINANCIER_API_KEY = "d77bc90f-6824-47da-94c2-2930de73daad"
//export const SFA_LOGIN_URL = SFA_URL+"admin/admin/logout";
export const SFA_NEW_URL = process.env.REACT_APP_SFA_NEW_URL || "https://sfa.oto.com/";
export const ESIGN_BASE_URL = SFA_NEW_URL + "user/esign_agreement_log_view";
export const SFA_LOGIN_URL = SFA_NEW_URL + "login";
export const EXECUTIVE_ROLES = [3];
export const VISIT_EXECUTIVE_ROLES = [4,5,6];

export const VIRTUAL_ADMIN_ROLE_ID = 1
export const SALES_OFFICER_ROLE_ID = 6