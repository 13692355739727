import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MasterService from '../../services/MasterService';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
import InputField from '../common/Input';
const EditOfficeAddress = (props) => {
    const [loading, setLoading] = useState(false);
    const [errors, setError] = useState({});
    const [office_address, setOfficeAddress] = useState({})
    const historyLead = useHistory();

    useEffect(() => {
        setOfficeAddress(props.location.state)
    }, [props.location.state])

    const updateOfficeAddress = async () => {
        await MasterService.post('/sfa/admin/update_office_address', office_address).then(function (response) {
            if (response.data.status === 200) {
                toast.success(response.data.data);
                historyLead.push('/office_address')
            } else {
                toast.error(response.data.message);
            }
        }).catch(function (response) {
        });
        setLoading(false)
    }

    const handleChange = (event) => {
        let { value, name } = event.target
        let temp_office_address = { ...office_address }
        temp_office_address[name] = value
        setOfficeAddress(temp_office_address)
    }

    const handleUpdate = async (value) => {
        if (value === 'cancel') {
            historyLead.push('/office_address')
        } else {
            let res = await isValidate()
            if (res) {
                updateOfficeAddress()
            } else {
                setLoading(false)
            }
        }
    }

    const isValidate = async () => {
        setLoading(true)
        let temp_office_address = { ...office_address };
        let formIsValid = true;
        let tempErrors = {};
        if (!temp_office_address["name"]) {
            formIsValid = false;
            tempErrors["name"] = 'Name is required';
        }
        if (!temp_office_address["address"]) {
            formIsValid = false;
            tempErrors["address"] = 'Address is required';
        }
        if (!temp_office_address["latitude"]) {
            formIsValid = false;
            tempErrors["latitude"] = 'Latitude is required';
        }

        if (!temp_office_address["longitude"]) {
            formIsValid = false;
            tempErrors["longitude"] = 'Longitude is required';
        }

        setError(tempErrors);
        return formIsValid;
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="top-heading d-flex">
                    <h1>Office Addresses</h1>
                </div>
                <div className='role-details-form p-xxl add-user-detail-filed'>
                    <div className='row'>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <InputField type="text" error={(errors.name) ? errors.name : ''} name="name" id="name" label="Name" placeholder=" " value={office_address.name} onChange={(e) => { handleChange(e) }} required={true} />
                                <label data-label="Name*" className="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <InputField type="text" error={(errors.address) ? errors.address : ''} name="address" id="address" label="Address" placeholder=" " value={office_address.address} onChange={(e) => { handleChange(e) }} required={true} />
                                <label data-label="Address*" className="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <InputField type="number" error={(errors.latitude) ? errors.latitude : ''} name="latitude" id="latitude" label="Latitude" placeholder=" " value={office_address.latitude} onChange={(e) => { handleChange(e) }} required={true} />
                                <label data-label="Latitude*" className="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <InputField type="longitude" error={(errors.longitude) ? errors.longitude : ''} name="longitude" id="longitude" label="Longitude" placeholder=" " value={office_address.longitude} onChange={(e) => { handleChange(e) }} required={true} />
                                <label data-label="Longitude*" className="form-label"></label>
                            </div>
                        </fieldset>

                    </div>
                    <div className='br-top-flex'>
                        <button className='btn-line m-xs-l' onClick={() => handleUpdate('cancel')}>
                            Cancel
                        </button>
                        <button className='btn-primary' onClick={() => handleUpdate('update')}>
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default EditOfficeAddress;