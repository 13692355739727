import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import './App.scss';
import Header from "./view/common/Header"
import Footer from './view/common/Footer'
import Routes from "./routes/routes";
import { renderRoutes } from "react-router-config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} />
        <div>
          <Header />
          <Switch>{renderRoutes(Routes)}</Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;

