import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { Business, Vertical, Zone } from "../../config/constant";
import { toast } from "react-toastify";
import { MasterService } from "../../services";
import Loader from "../common/loader";
import Tooltip from 'react-tooltip-lite';


const RoleUserDetail = (props) => {
    const [loading, setLoading] = useState(false);
    const [roleDetails, setRoleDetails] = useState({});
    const [stateNameList, setStateNameList] = useState([]);
    const [cityNameList, setCityNameList] = useState([]);
    const [roleNameList, setRoleNameList] = useState([]);
    const [tipOpenState, setTipOpenState] = useState(false);
    const [tipOpenCity, setTipOpenCity] = useState(false);
    const [isEdit, setIsEdit] = useState(roleDetails && roleDetails.role_id && roleDetails.role_id !== "" ? true : false);
    const historyLead = useHistory();
    const { role_id } = useParams();

    const tipContentRef = React.createRef();

    const handleEdit = (pathname) => {
        historyLead.push(pathname, { role_id });
    }

    useEffect(() => {
        getRoleDetailsById(role_id);
    }, [])

    useEffect(() => {
        document.addEventListener('mousedown', bodyClickState);
        document.addEventListener('mousedown', bodyClickCity);
        return () => {
            document.removeEventListener('mousedown', bodyClickState);
            document.removeEventListener('mousedown', bodyClickCity);
        };
    }, []);

    function toggleTipCity() {
        setTipOpenCity(prevState => !prevState);
    }

    function toggleTipState() {
        setTipOpenState(prevState => !prevState);
    }

    function bodyClickState(e) {
        setTipOpenState(false);
    }

    function bodyClickCity(e) {
        setTipOpenCity(false);
    }

    const getCityList = async () => {
        try {
            let response = await MasterService.post('/sfa/user/city_list', {});
            if (response.status === 200) {
                let cityList = response.data.data;
                setCityNameList(cityList);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const getStateList = async () => {
        try {
            let response = await MasterService.post('/sfa/user/state_list', {});
            if (response.status === 200) {
                let stateList = response.data.data;
                setStateNameList(stateList);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const getRoleList = async () => {
        try {
            let response = await MasterService.post('/sfa/user/role_list', {});
            if (response.status === 200) {
                let roleList = response.data.data;
                setRoleNameList(roleList);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const getRoleDetailsById = (id) => {
        setLoading(true);
        MasterService.post('/sfa/role/get_role_view', { 'role_id': id })
            .then(async (response) => {
                if (response.data.status === 200) {
                    response.data.data.business_unit = Business;
                    response.data.data.business_vertical = Vertical;
                    response.data.data.zone_id = Zone;
                    await getCityList();
                    await getStateList();
                    setRoleDetails(response.data.data);
                    await getRoleList();
                    setLoading(false);
                } else {
                    toast.error(response.data.message)
                    setLoading(false);
                }
            })
            .catch((err) => {
                toast.error(err)
                setLoading(false);
            })
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="top-bar">
                    <div className="top-heading d-flex">
                        <h1>Role Details</h1>
                        <div className="right-btn btn-agent-detail">
                            {/* <button className="btn-line m-xs-l">
                                <i
                                    className="ic-edit"
                                    onClick={() => { handleEdit( `/edit-role/role_details_info?id=${role_id}`); }}
                                ></i>{" "}
                                Edit
                            </button> */}
                        </div>
                    </div>
                </div>
                <div className="agent-detail-tads role-detail-tabs">
                    <div className="preview-confirmation add-user-detail-filed">
                        <div className="top-heading d-flex br-radius">
                            <h2>Role Details</h2>
                            {/* <a href="" className="edit-opt" onClick={() => { handleEdit(`/edit-role/role_details_info?id=${role_id}`) }}>
                                Edit
                            </a> */}
                        </div>

                        <div className="d-flex">
                            <div className="role-detail-preview">
                                <h3>Business Vertical</h3>
                                <ul>
                                    <li>
                                        <span>Business</span>
                                        <label>
                                            {roleDetails &&
                                                roleDetails.business_unit &&
                                                roleDetails.business_unit.map(
                                                    (item) => item.label
                                                )}
                                        </label>
                                    </li>
                                    <li>
                                        <span>Vertical</span>
                                        <label>
                                            {roleDetails &&
                                                roleDetails.business_vertical &&
                                                roleDetails.business_vertical.map(
                                                    (item) => item.label
                                                )}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="d-flex m-xl-t p-sm-t m-xl-b p-md-b">
                            <div className="role-detail-preview role-informatiom">
                                <h3>Role Information</h3>
                                <ul>
                                    <li>
                                        <span>Role name</span>
                                        <label>
                                            {roleDetails &&
                                                roleDetails.role_name &&
                                                roleDetails.role_name}
                                        </label>
                                    </li>
                                    <li>
                                        <span>Status</span>
                                        <label>
                                            {roleDetails &&
                                                roleDetails.role_status &&
                                                roleDetails.role_status === "1"
                                                ? "Active"
                                                : "Inactive"}
                                        </label>
                                    </li>
                                    {/* <li>
                                        <span>Digital Consultant</span>
                                        <label>
                                            {roleDetails.digital_consultant &&
                                                roleDetails.digital_consultant ===
                                                "1"
                                                ? "Activate"
                                                : "Deactivate"}
                                        </label>
                                    </li> */}
                                    <li>
                                        <span>Role Discription</span>
                                        <label>
                                            {roleDetails &&
                                                roleDetails.role_description &&
                                                roleDetails.role_description}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="role-detail-preview  m-xl-t p-sm-t m-xl-b p-md-b">
                            <h3>Map Region</h3>
                            <ul>
                                <li>
                                    <span>Zone</span>
                                    <label>
                                        {roleDetails &&
                                            roleDetails.zone_id &&
                                            roleDetails.zone_id.map(
                                                (item) => item.label
                                            )}
                                    </label>
                                </li>
                                <li>
                                    <span>State</span>
                                    <label>
                                        {roleDetails &&
                                            roleDetails.states &&
                                            roleDetails.states.length}{" "}
                                        States{" "}
                                        <i className="ic-info-icon1 m-sm-l" onClick={toggleTipState} ></i>
                                        <Tooltip
                                            content={(
                                                <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                    <div className="tooltip-data">

                                                        <span className="controlled-example_close-button" onClick={toggleTipState}><i className="ic-clearclose"></i></span>
                                                        <ul className="user-detail">
                                                            {roleDetails && roleDetails.states && stateNameList && stateNameList.filter(state => roleDetails.states.includes(state.id)).map((item, k) => <li key={k}>{item.state_name}</li>)}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                            isOpen={tipOpenState}
                                            tagName="span"
                                            direction="down"
                                            className="tootip-outer"
                                            forceDirection
                                        >
                                        </Tooltip>
                                    </label>
                                </li>
                                <li>
                                    <span>City</span>
                                    <label>
                                        {roleDetails &&
                                            roleDetails.cities &&
                                            roleDetails.cities.length}{" "}
                                        Cities
                                        <i className="ic-info-icon1 m-sm-l" onClick={toggleTipCity} ></i>
                                        <Tooltip
                                            content={(
                                                <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                    <div className="tooltip-data">
                                                        <span className="controlled-example_close-button" onClick={toggleTipCity}><i className="ic-clearclose"></i></span>
                                                        <ul className="user-detail">
                                                            {roleDetails && roleDetails.cities && cityNameList && cityNameList.filter(city => roleDetails.cities.includes(city.id)).map((item, k) => <li key={k}>{item.city_name}</li>)}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                            isOpen={tipOpenCity}
                                            tagName="span"
                                            direction="down"
                                            className="tootip-outer"
                                            forceDirection
                                        >
                                        </Tooltip>
                                    </label>
                                </li>
                            </ul>
                        </div>

                        <div className="top-heading d-flex ">
                            <h2>Reporting Details</h2>
                            {/* <a href="" className="edit-opt" onClick={() => { handleEdit(`/edit-role/assign_rights?id=${role_id}`) }}>
                                Edit
                            </a> */}
                        </div>
                        <div className="d-flex">
                            <div className="role-detail-preview preview-basic-details">
                               {/*  <h3>Rights Detail</h3> */}
                                <ul>
                                    {/* <li>
                                        <span>Report of Role </span>
                                        <label>
                                            {roleNameList
                                                .filter((role) => roleDetails.reporting_from.includes(role.id))
                                                .map((item) => item.name)
                                                .join(",")}
                                        </label>
                                    </li> */}
                                    <li>
                                        <span>Reporting Manager</span>
                                        <label>
                                            {roleNameList
                                                .filter((role) => roleDetails.reporting_to.includes(role.id))
                                                .map((item) => item.name)
                                                .join(",")}
                                        </label>
                                    </li>
                                   {/*  <li>
                                        <span>Send Message</span>
                                        <label>All</label>
                                    </li>
                                    <li>
                                        <span>Reseive Message</span>
                                        <label>All</label>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        {/* <div className="d-flex  p-sm-t">
                            <div className="role-detail-preview">
                                <h3>Rights Detail</h3>
                                <ul className="right-detail-list">
                                    {roleDetails.feature_access &&
                                        roleDetails.feature_access.length > 0 &&
                                        roleDetails.feature_access.map(
                                            (rights, key) => {
                                                return (
                                                    <li key={key} className="custom-control custom-checkbox" >
                                                        <input
                                                            id={`sli${key}`}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={rights && rights.is_enabled === true}
                                                            readOnly
                                                        />
                                                        <label htmlFor={`sli${key}`} className="custom-control-label" >
                                                            {rights.name}
                                                        </label>
                                                    </li>
                                                );
                                            }
                                        )}
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default RoleUserDetail;
