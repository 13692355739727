import UserManagement from "../view/manage-user/UserManagement"
import UserManagementDetail from "../view/manage-user/UserManagementDetails"
import AddUser from "../view/manage-user/AddUser"
import EditUser from "../view/manage-user/EditUser"
import BasicDetailUpdated from "../view/manage-user/BasicDetailUpdated"
import DocumentUplaodPreview from "../view/manage-user/DocumentUplaodPreview"
import ViewUser from "../view/manage-user/ViewUser"
import AgentDetails from "../view/manage-user/AgentDetail"
import ViewUserProfile from "../view/manage-user/ViewUserProfile"
import UserLogin from "../view/user-login/UserLogin"
import RoleManagement from "../view/manage-role/RoleManagement"
import AddRole from "../view/manage-role/AddRole"
import RoleUserDetail from "../view/manage-role/RoleUserDetail"
import EditRole from "../view/manage-role/EditRole.js"
import progressionList from "../view/progression/progressionList"
import DealerUserListing from "../view/dealer-user-listing/DealerUserListing"
import VisitReport from "../view/view-report/VisitReport"
import MapDealerManagement from "../view/map-dealers/MapDealer"
import logout from "../view/user-login/logout"
import Login from "../view/login/Login"
import ESIGNLOGVIEW from "../view/esign/EsignView"
import OfficeAddress from "../view/admin/OfficeAddress"
import EditOfficeAddress from "../view/admin/EditOfficeAddress"
import ResetMaxAttempts from "../view/admin/ResetMaxAttempts"
import NoVisitReport from "../view/no-view-report/NoVisitReport"
import PageNotFound from "../view/login/PageNotFound";
import ls from "local-storage";
const logged_Data = ls.get('logged_data');
let Routes = [];
if(!logged_Data?.token){
	Routes = [
		{ path: "/", exact: true, component: Login },
		{ path: "/user-management",exact: true, component: Login },	
		{ path: "/user-management-detail", component: Login },
		{ path: "/add-user", component: Login },
		{ path: "/basic-detail-updated", component: Login },
		{ path: "/document-preview", component: Login },
		{ path: "/agent-detail", component: Login },
		{ path: "/view-user/:user_id", component: Login },
		{ path: "/edit-user/:user_id", component: Login },
		{ path: "/view-profile/:user_id", component: Login },
		{ path: "/user-login",exact: true, component: Login },
		{ path : "/role-management", component : Login},
		{ path : "/add-role", component : Login}, 	
		{ path: "/progression-config", component:Login },
		{ path: "/dealer-user-listing", component: Login },		
		{ path: "/user/esign_agreement_log_view", component: Login},
		{ path : "/view-role/:role_id", component : Login},
		{ path : '/edit-role/:role_id', component : Login},	
		{ path: "/map-dealer", component: Login },
		{ path: "/logout", component:Login },
		{ path: "/login", component: Login },
		{ path: "/view-report", component: Login },
		{ path: "/office_address", component: Login },
		{ path: "/edit_office_address", component: Login },
		{ path: "/reset_max_attempts", component: Login },
		{ path: "/no-view-report", component: Login },
		{ path: "/*", component: PageNotFound },
	];
}
if(logged_Data?.token){
Routes = [
	{ path: "/", exact: true, component: Login },
	{ path: "/user-management",exact: true, component: UserManagement },	
	{ path: "/user-management-detail", component: UserManagementDetail },
	{ path: "/add-user", component: AddUser },
	{ path: "/basic-detail-updated", component: BasicDetailUpdated },
	{ path: "/document-preview", component: DocumentUplaodPreview },
	{ path: "/agent-detail", component: AgentDetails },
	{ path: "/view-user/:user_id", component: ViewUser },
	{ path: "/edit-user/:user_id", component: EditUser },
	{ path: "/view-profile/:user_id", component: ViewUserProfile },
	{ path: "/user-login",exact: true, component: UserLogin },
	{ path : "/role-management", component : RoleManagement},
	{ path : "/add-role", component : AddRole}, 	
	{ path: "/progression-config", component:progressionList },
	{ path: "/dealer-user-listing", component: DealerUserListing },		
	{ path: "/user/esign_agreement_log_view", component: ESIGNLOGVIEW},
	{ path : "/view-role/:role_id", component : RoleUserDetail},
	{ path : '/edit-role/:role_id', component : EditRole},	
	{ path: "/map-dealer", component: MapDealerManagement },
	{ path: "/logout", component:logout },
	{ path: "/login", component: Login },
	{ path: "/view-report", component: VisitReport },
	{ path: "/office_address", component: OfficeAddress },
	{ path: "/edit_office_address", component: EditOfficeAddress },
	{ path: "/reset_max_attempts", component: ResetMaxAttempts },
	{ path: "/no-view-report", component: NoVisitReport },
	{ path: "/*", component: PageNotFound },
];
}

export default Routes;
