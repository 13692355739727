import React, { Children, Component, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { RoleId } from '../../config/constant';
import ToggleSwitch from "../common/ToggleSwitch"
import AppsSubmittedCard from './AppsSubmittedCard';
import BasicFilter from './BasicDetailFilter';
import DescriptionCard from './DescriptionCard';
// import IncentiveAccordionItemPanel from './IncentiveAccordionItemPanel'


const AddProgression = (props) => {


    useEffect(() => {
    }, [props.progression_list, props.tempErrors])

    useEffect(() => {
    }, [props.config])

    const handleParentClicks = (e, index, id) => {
        e.stopPropagation()
        if (e.target.className.indexOf('accordion__button') !== -1) {
            if (e.target['ariaExpanded'] == 'true') {
                props.showExpandedTabUuid(-1, id);
            }
            else {
                props.showExpandedTabUuid(index, id)
            }
        }

        //ON HEADING CLICK OPEN/CLOSE HANDLER
        if (e.target.attributes && e.target.attributes.accordionstatus && e.target.attributes.accordionstatus.nodeValue) {
            if (e.target.attributes.accordionstatus.nodeValue === 'open') {
                props.showExpandedTabUuid(-1, id);
            } else {
                props.showExpandedTabUuid(index, id)
            }
        }
    }

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', {
            month: 'long',
        });
    }

    let { activeTab, progression_list, tempErrors, configList, config } = props
    return (
        <React.Fragment>
            <div className="add-incentive-accordion">
                {progression_list && progression_list.length && progression_list.map((element, index) => (
                    <div key={"parent" + index}>
                        <Accordion allowMultipleExpanded={false} allowZeroExpanded="true">
                            <AccordionItem onClick={(e) => { handleParentClicks(e,index, element.id) }} key={index} uuid={index} dangerouslySetExpanded={element.preExpandedTabs>-1?true:false}>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <h2>{"Progression" + (RoleId[element.role_id] ? " - " + RoleId[element.role_id] : "") + (config.business_line && config.business_line.findIndex(({ value }) => value === element.business_line) > -1 ? " - " + config.business_line.filter(({ value }) => value === element.business_line)[0].value.toUpperCase() : "") + (element.month ? " - " + getMonthName(element.month) : "")} </h2>
                                        {activeTab === 1  && element.modify_status === 1 && 
                                            <ul className='right-action-item' style={{ zIndex: "999999" }}>
                                                <div className="on-off-switch">
                                                    <ToggleSwitch status={element.status} is_expired={element.is_expired} onChange={() => { element.status == 1 ? props.showModalpopup("ModifyStatusPopup", index) : props.handleChange('status', 1, index) }} />
                                                </div>
                                                <li onClick={() => { props.showModalpopup("DeleteProgressionPopup", index)}}><i className='ic-delete-icn'></i></li>

                                            </ul>
                                        }

                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className='sub-accordion'>
                                        <Accordion allowZeroExpanded="true" >
                                            <div className={element.scheme_type_id === "3" ? 'basic-top-filter-outer' : ""}>
                                                <BasicFilter config={config} index={index} element={element} configList={configList} onChange={(e, i, q) => { props.handleChange(e, i, q) }} tempErrors={tempErrors} />
                                            </div>
                                            <div className='left-right-panel-outer progression-left-right'>
                                                <div className='add-incenative-left-panel'>
                                                    {element.role_id &&
                                                        <AppsSubmittedCard config={config} index={index} element={element} onChange={(e, i, q) => { props.handleChange(e, i, q) }} tempErrors={tempErrors} />
                                                    }
                                                    <DescriptionCard config={config} index={index} element={element} onChange={(e, i, q) => { props.handleChange(e, i, q) }} onUpdate={() => { props.handleUpdate(element, index) }} onCancel={() => { props.handleCancel(element, index) }} />
                                                </div>
                                            </div>
                                        </Accordion>
                                    </div>

                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                ))}
            </div>
            <div className="container-fluid">
                {/* <div className="add-incentive-accordion m-lg-t"> */}


                {/* </div> */}

            </div>

        </React.Fragment>
    )
}
export default AddProgression;