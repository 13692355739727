import React, { useState } from 'react';
import { useHistory } from "react-router-dom";


const RoleManagementTable = ({ roleMapping, roleRights, getRoleRights }) => {
    const [rightsDetails, setRightsDetails] = useState(false);
    const [currentRole, setCurrentRole] = useState('')
    const [userRoleId, setUserRoleId] = useState();
    const historyLead = useHistory();

    const handleEdit = (role_id) => {
        historyLead.push(`/edit-role/assign_rights?id=${role_id}`);
    }
    
    const showModalRight = (role_id, role_name) => {
        setRightsDetails(true);
        setCurrentRole(role_name);
        getRoleRights(role_id, role_name);
        setUserRoleId(role_id);
        document.body.classList.add("overflow-hidden");
    }

    const handleView = (role_id) => {
        historyLead.replace(`/view-role/${role_id}`)
    }

    const hideModalRight = () => {
        setRightsDetails(false);
        document.body.classList.remove("overflow-hidden");
    }

    return (
        <React.Fragment>
            <div className='user-role-management-table data-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Role Name</th>
                            <th>Description</th>
                            <th>Region</th>
                            {/* <th>Rights</th> */}
                            <th>User Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            roleMapping && roleMapping.length > 0 && roleMapping.map((role, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{role.name}</td>
                                        <td>{role.description}</td>
                                        <td>{role.regionCount}, Regions, {role.stateCount} States, {role.cityCount} Cities</td>
                                        {/* <td><a onClick={() => { showModalRight(role.role_id, role.name) }}>View</a></td> */}
                                        <td><span className={role.status === '1' ? 'status-active' : 'status-inactive'} >{role.status === '1' ? 'Active' : 'Inactive'}</span></td>
                                        <td><a href="" onClick={() => {handleView(role.role_id)}} >View Details</a></td>
                                    </tr>
                                )
                            })

                        }
                    </tbody>
                </table>
            </div>
            <div className="role-managment-detail-popup model-popup-outer">
                <Modal show={rightsDetails} handleClose={hideModalRight} >
                    <div className="modal-header">
                        <h2>{currentRole} Rights details</h2>
                        <button className='btn-line' onClick={() => {handleEdit(userRoleId)}}><i className='ic-edit'></i> Edit</button>
                    </div>
                    <div className="modal-body">
                        <ul>
                            {
                                roleRights.map((right,k) => {
                                    return (
                                        <li key={k}><i className={right.is_enabled ? 'ic-check' : 'ic-clearclose'}></i>{right.name}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )

}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';
    
    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};


export default RoleManagementTable;