import axios from 'axios';
import { API_URL, FINANCIER_API_KEY } from '../config/constant';
import MasterService from './MasterService';


export const FinanceService = {
    ...MasterService,
    
    getRefinanceFinanciers(headers) {
        headers = headers || {};
        headers.apikey = FINANCIER_API_KEY
        return axios.get(
            `${API_URL.COMMON_GATEWAY_API}loan/financier/get_company?lead_source=refinance`,
            { headers }
        );
    },
    getBikeRefinanceFinanciers(headers) {
        headers = headers || {};
        headers.apikey = FINANCIER_API_KEY
        return axios.get(
            `${API_URL.COMMON_GATEWAY_API}loan/financier/get_company?lead_source=ub_refinance`,
            { headers }
        );
    },
    getFinanceFinanciers(headers) {
        headers = headers || {};
        headers.apikey = FINANCIER_API_KEY
        return axios.get(
            `${API_URL.COMMON_GATEWAY_API}loan/financier/get_company?lead_source=finance`,
            { headers }
        );
    }

}