import React, { useEffect } from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Select, { components } from 'react-select';
// import Dropdown from 'react-bootstrap/Dropdown'
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const BasicFilter = (props) => {

    useEffect(() => {
    }, [props.config, props.element.role_id, props.element.business_line, props.element.month, props.element.application_type, props.element.ucf_financier, props.element.ubrf_financier, props.element.ucrf_loanbox_financier, props.tempErrors])

    const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        let text = placeholderButtonLabel.split("*")[0]
        if (value && value.some((o) => o.value === "*")) {
            let temp_ucrf_selected_value = []
            let temp_ubrf_selected_value = []
            if (text === "UCRF Financiers") {
                temp_ucrf_selected_value = getSelectedValue(props.configList.ucrf_loanbox_financier)
            }
            if (text === "UBRF Financiers") {
                temp_ubrf_selected_value = getSelectedValue(props.configList.ubrf_financier)
            }
            if (text === "UCRF Financiers") {
                if (props.element.ucrf_loanbox_financier && props.element.ucrf_loanbox_financier.length === 0) {
                    return `${placeholderButtonLabel}`;
                } else if (props.element.ucrf_loanbox_financier && props.element.ucrf_loanbox_financier.length) {
                    if (value?.length === temp_ucrf_selected_value.length) {
                        return `All selected`;
                    } else {
                        return `${props.element.ucrf_loanbox_financier.length} selected`;
                    }
                } else {
                    return `${placeholderButtonLabel}`;
                }
            } else if (text === "UBRF Financiers") {
                if (props.element.ubrf_financier && props.element.ubrf_financier.length === 0) {
                    return `${placeholderButtonLabel}`;
                } else if (props.element.ubrf_financier && props.element.ubrf_financier.length) {
                    if (value?.length === temp_ubrf_selected_value.length) {
                        return `All selected`;
                    } else {
                        return `${props.element.ubrf_financier.length} selected`;
                    }
                } else {
                    return `${placeholderButtonLabel}`;
                }
            } else {
                return `All selected`;
            }

        } else if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            if (text === "Application Type" && value?.length === props.config.application_type[0].options.length) {
                return `All selected`;
            } else {
                return `${value.length} selected`;
            }
        } else {
            return `${placeholderButtonLabel}`;
        }
    }

    const handleChangeSelect = (option, key, index) => {
        if (!props.element.is_expired) {
            props.onChange(key, option, index)
        }
    };

    const handleChangeMultiSel = (index, field_name, field_obj, event) => {
        if (!props.element.is_expired) {
            let selected_value = []
            let temp_ucrf_selected_value = []
            let temp_ubrf_selected_value = []
            if (field_name === "ucrf_loanbox_financier") {
                temp_ucrf_selected_value = getSelectedValue(props.configList.ucrf_loanbox_financier)
            }
            if (field_name === "ubrf_financier") {
                temp_ubrf_selected_value = getSelectedValue(props.configList.ubrf_financier)
            }

            if (event.action === "select-option" && event.option && event.option.value === "*") {
                if (field_name === "application_type") {
                    selected_value = props.config.application_type
                }
                if (field_name === "ucf_financier") {
                    selected_value = [{ label: "Select All", value: "*" }, ...props.configList.ucf_financier]
                }
                if (field_name === "ucrf_loanbox_financier") {
                    selected_value = temp_ucrf_selected_value
                }
                if (field_name === "ubrf_financier") {
                    selected_value = temp_ubrf_selected_value
                }
            } else if (event.action === "deselect-option" && event.option.value === "*") {
                selected_value = []
            } else if (event.action === "deselect-option" && event.option.value !== "*") {
                selected_value = field_obj.filter((o) => o.value !== "*")
            } else if (field_name === "application_type" && field_obj.length === props.config.application_type[0].options.length) {
                let new_field_obj = field_obj.map(({ value }) => value)
                selected_value = props.config.application_type[0].options.filter(e => new_field_obj.includes(e.value))
            } else if (field_name === "ucf_financier" && field_obj.length === props.configList.ucf_financier.length) {
                selected_value = [{ label: "Select All", value: "*" }, ...props.configList.ucf_financier]
            } else if (field_name === "ucrf_loanbox_financier" && field_obj.length === temp_ucrf_selected_value.length) {
                selected_value = temp_ucrf_selected_value
            } else if (field_name === "ubrf_financier" && field_obj.length === temp_ubrf_selected_value.length) {
                selected_value = temp_ubrf_selected_value
            } else {
                selected_value = field_obj
            }
            if (field_name === "application_type") {
                if(event.action === "deselect-option"){
                    if(event.option.value === 'UCF'){
                        props.onChange('ucf_financier', [], index)
                    }
                    if(event.option.value === 'UCRF'){
                        props.onChange('ucrf_loanbox_financier', [], index)
                    }
                    if(event.option.value === 'UBRF'){
                        props.onChange('ubrf_financier', [], index)
                    }
                }
                props.onChange(field_name, selected_value, index)
            }
            if (field_name === "ucf_financier") {
                props.onChange(field_name, selected_value, index)
            }
            if (field_name === "ucrf_loanbox_financier") {
                props.onChange(field_name, selected_value, index)
            }
            if (field_name === "ubrf_financier") {
                props.onChange(field_name, selected_value, index)
            }
        }
    }

    const getSelectedValue = (temp_selected_value) => {
        let final_selected_value = []
        temp_selected_value.forEach((option) => {
            if (option.options) {
                final_selected_value = [...option.options, ...final_selected_value]
            }
        });
        return final_selected_value
    }

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', {
            month: 'long',
        });
    }

    // const { product_type, scheme_duration, applicable_to } = this.props.incentive_config
    const { tempErrors, element, index, configList, config } = props
    return (
        <React.Fragment>
            <div className='basic-filter-outer'>
                <h2>Basic Details </h2>

                <div className="basic-detail-filter progression-top-filter">

                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={element.role_id && config.role.findIndex(({ value }) => parseInt(value) === element.role_id) > -1 ? config.role.filter(({ value }) => parseInt(value) === element.role_id) : null}
                                onChange={(e) => handleChangeSelect(e, 'role_id', index)}
                                options={config.role}
                                isDisabled={element.is_expired}
                                placeholder="User Role*"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                            {
                                (tempErrors && tempErrors["user_role_" + index]) ? <span className="error show">{tempErrors["user_role_" + index]}</span> : ''
                            }

                        </div>
                    </fieldset>

                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={element.business_line && config.business_line.findIndex(({ value }) => value === element.business_line) > -1 ? config.business_line.filter(({ value }) => value === element.business_line) : null}
                                onChange={(e) => handleChangeSelect(e, 'business_line', index)}
                                options={config.business_line}
                                isDisabled={element.is_expired}
                                placeholder="Business Line*"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                            {
                                (tempErrors && tempErrors["business_line_" + index]) ? <span className="error show">{tempErrors["business_line_" + index]}</span> : ''
                            }

                        </div>
                    </fieldset>

                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={element.month && config.month.findIndex(({ value }) => value === element.month) > -1 ? config.month.filter(({ value }) => value === element.month) : (element.month ? { value: element.month, label: getMonthName(element.month), year: element.year } : null)}
                                onChange={(e) => handleChangeSelect(e, 'month', index)}
                                options={config.month}
                                isDisabled={element.is_expired}
                                placeholder="Month*"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                            {
                                (tempErrors && tempErrors["month_" + index]) ? <span className="error show">{tempErrors["month_" + index]}</span> : ''
                            }

                        </div>
                    </fieldset>

                    <fieldset className="multiselect-dropDown">
                        <div className="material">

                            <ReactMultiSelectCheckboxes
                                isMulti
                                options={config.application_type}
                                placeholderButtonLabel="Application Type*"
                                iconAfter="false"
                                getDropdownButtonLabel={getDropdownButtonLabel}
                                value={element.application_type}
                                onChange={handleChangeMultiSel.bind(this, index, "application_type")}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                            {(element.application_type && element.application_type.length > 0) ? <label data-label="Application Type*" className="form-label date-label"></label> : ''}
                            {
                                (tempErrors && tempErrors["application_type_" + index]) ? <span className="error show">{tempErrors["application_type_" + index]}</span> : ''
                            }
                        </div>
                    </fieldset>

                    {element.application_type.length > 0 && element.application_type.map((ele, i) => (
                        <fieldset className="multiselect-dropDown ">
                            {ele.value === "UCF" &&
                                <div className="material">

                                    <ReactMultiSelectCheckboxes
                                        isMulti
                                        options={[{ label: "Select All", value: "*" }, ...configList.ucf_financier]}
                                        placeholderButtonLabel={ele.value + " Financiers*"}
                                        iconAfter="false"
                                        getDropdownButtonLabel={getDropdownButtonLabel}
                                        value={element.ucf_financier?.length === configList.ucf_financier?.length ? [{ label: "Select All", value: "*" }, ...configList.ucf_financier] : element.ucf_financier}
                                        onChange={handleChangeMultiSel.bind(this, index, "ucf_financier")}
                                    />
                                    {(element.ucf_financier && element.ucf_financier.length > 0) ? <label data-label={ele.value + " Financiers*"} className="form-label date-label"></label> : ''}
                                    {
                                        (tempErrors && tempErrors["ucf_financier_" + index]) ? <span className="error show">{tempErrors["ucf_financier_" + index]}</span> : ''
                                    }
                                </div>
                            }
                            {ele.value === "UCRF" &&

                                <div className="material">

                                    <ReactMultiSelectCheckboxes
                                        isMulti
                                        options={configList.ucrf_loanbox_financier}
                                        placeholderButtonLabel={ele.value + " Financiers*"}
                                        iconAfter="false"
                                        getDropdownButtonLabel={getDropdownButtonLabel}
                                        value={element.ucrf_loanbox_financier}
                                        onChange={handleChangeMultiSel.bind(this, index, "ucrf_loanbox_financier")}
                                    />
                                    {(element.ucrf_loanbox_financier && element.ucrf_loanbox_financier.length > 0) ? <label data-label={ele.value + " Financiers*"} className="form-label date-label"></label> : ''}
                                    {
                                        (tempErrors && tempErrors["ucrf_financier_" + index]) ? <span className="error show">{tempErrors["ucrf_financier_" + index]}</span> : ''
                                    }
                                </div>

                            }
                            {ele.value === "UBRF" &&
                                <div className="material">

                                    <ReactMultiSelectCheckboxes
                                        isMulti
                                        options={configList.ubrf_financier}
                                        placeholderButtonLabel={ele.value + " Financiers*"}
                                        iconAfter="false"
                                        getDropdownButtonLabel={getDropdownButtonLabel}
                                        value={element.ubrf_financier}
                                        onChange={handleChangeMultiSel.bind(this, index, "ubrf_financier")}
                                    />
                                    {(element.ubrf_financier && element.ubrf_financier.length > 0) ? <label data-label={ele.value + " Financiers*"} className="form-label date-label"></label> : ''}
                                    {
                                        (tempErrors && tempErrors["ubrf_financier_" + index]) ? <span className="error show">{tempErrors["ubrf_financier_" + index]}</span> : ''
                                    }
                                </div>
                            }
                        </fieldset>
                    ))}

                </div>
            </div>
        </React.Fragment>
    )
}
export default BasicFilter;