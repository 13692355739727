import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import ToggleSwitch from "../common/ToggleSwitch"
import { toast } from 'react-toastify';
import AddProgression from './AddProgression';
import { MasterService } from '../../services';
import { FinanceService } from '../../services/FinanceService';

const ProgressionList = (props) => {
    const historyUser = useHistory();
    const [DeleteProgressionPopup, setDeleteProgressionPopup] = useState(false);
    const [AddProgressionPopup, setAddProgressionPopup] = useState(false);
    const [ModifyStatusPopup, setModifyStatusPopup] = useState(false);
    const [status, setStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [tempErrors, setTempErrors] = useState({});
    const [configList, setConfigList] = useState({});
    const [config, setConfig] = useState({})
    const [selected_index, setSelectedIndex] = useState(0);
    const [progression_list, setProgressionList] = useState([]);
    const [expired_progression_list, setExpiredProgressionList] = useState([])
    const [progressionAllData, setProgressionAllData] = useState([]);
    
    useEffect(() => {
        getProgressionList()
    }, [])

    const showModalpopup = (name, index) => {
        if (name === "DeleteProgressionPopup") {
            setDeleteProgressionPopup(true)
        } else if (name === "ModifyStatusPopup") {
            setModifyStatusPopup(true)
        }
        setSelectedIndex(index)
    }

    const hideModalpopup = (name) => {
        if (name === "DeleteProgressionPopup") {
            setDeleteProgressionPopup(false)
        } else if (name === "ModifyStatusPopup") {
            setModifyStatusPopup(false)
        }
        setSelectedIndex(0)
    }

    const handleDeleteYes = () => {
        let tempProgressionData = [...progression_list]
        let element = tempProgressionData[selected_index]
        if(element.id){
            updateProgressionStatus(element, 4)
        }
        tempProgressionData.splice(selected_index, 1);
        setProgressionList(tempProgressionData)
        hideModalpopup("DeleteProgressionPopup")
    }

    const getProgressionList = async () => {
        setLoading(true)
        let temp_config = await getProgressionConfig()
        let get_config = await getFinanciers()
        await MasterService.post('/sfa/user/get-progression-list', { data_status: activeTab == 1 ? activeTab : 0 })
            .then(function (response) {
                if (response.data.status === 200) {
                    let ubrf_selected_temp = getSelectedValue(get_config.ubrf_financier)
                    let ucrf_selected_temp = getSelectedValue(get_config.ucrf_loanbox_financier)

                    let progression_data = response.data.data.progression_data

                    let final_progression_data = progression_data.map((obj) => ({
                        ...obj,
                        is_expired: obj.status == 0 ? true : false,
                        application_type: temp_config.application_type[0].options.filter(e => obj.application_type.includes(e.value)),
                        ucf_financier: get_config.ucf_financier.filter(e => obj.ucf_financier.split(',').includes(e.value.toString())),
                        ubrf_financier: ubrf_selected_temp.filter(e => obj.ubrf_financier.split(',').includes(e.value.toString())),
                        ucrf_loanbox_financier: ucrf_selected_temp.filter(e => obj.ucrf_loanbox_financier.split(',').includes(e.value.toString()))
                    }))
                    setProgressionAllData(final_progression_data)
                    let temp_active_progression_list = final_progression_data.filter(e => e.status == 1 || e.status == 2)
                    let temp_expired_progression_list = final_progression_data.filter(e => e.status == 0)
                    setProgressionList(temp_active_progression_list)
                    setExpiredProgressionList(temp_expired_progression_list)
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
    }

    const getSelectedValue = (temp_selected_value) => {
        let final_selected_value = []
        temp_selected_value.forEach((option) => {
            if (option.options) {
                final_selected_value = [...option.options, ...final_selected_value]
            }
        });
        return [{ label: "Select All", value: "*" }, ...final_selected_value]
    }

    const getProgressionConfig = async () => {
        let temp_config = {}
        await MasterService.post('/sfa/user/get-progression-config', {restrict_role:true})
            .then(function (response) {
                if (response.data.status === 200) {
                    setConfig(response.data.data)
                    temp_config = response.data.data
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
        return temp_config
    }

    const getConfigList = async (role_id, tempProgressionData, index) => {
        MasterService.post('/sfa/user/get-config', { role_id: role_id, is_progression:true })
            .then(function (response) {
                if (response.data.status === 200) {
                    let temp_config = {...config}
                    let user_level_tagging = response.data.data.tagging_roles.map((obj) => ({
                        ...obj,
                        operator1: "",
                        m2_lead_count: "",
                        operator2: "",
                        m1_lead_count: ""
                    }))
                    tempProgressionData[index].user_level_tagging = user_level_tagging
                   /*  temp_config.business_line = response.data.data.business_line */
                    setProgressionList(tempProgressionData)
                    setConfig(temp_config)
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
    }

    const getFinanciers = async () => {
        let get_config = {}
        await Promise.all([FinanceService.getRefinanceFinanciers(), FinanceService.getBikeRefinanceFinanciers(), FinanceService.getFinanceFinanciers()]).then((response) => {
            let get_refinance_financier = response[0].data.data
            let ho_get_refinance_financier = get_refinance_financier.filter(e => e.partnership_type.refinance === "HO Partnership")
            let branch_get_refinance_financier = get_refinance_financier.filter(e => e.partnership_type.refinance === "Branch Partnership")
            let final_ho_get_refinance_financier = ho_get_refinance_financier.map((obj) => ({
                "key": "HO Partnership",
                label: obj.name,
                value: obj.id
            }))

            let final_branch_get_refinance_financier = branch_get_refinance_financier.map((obj) => ({
                "key": "Branch Partnership",
                label: obj.name,
                value: obj.id
            }))

            let final_get_refinance_financier = [
                {
                    options: final_ho_get_refinance_financier,
                    "label": "HO Partnership"
                },
                {
                    options: final_branch_get_refinance_financier,
                    "label": "Branch Partnership"
                }
            ]

            let get_bike_refinance_financier = response[1].data.data
            let ho_get_bike_refinance_financier = get_bike_refinance_financier.filter(e => e.partnership_type.refinance === "HO Partnership")
            let branch_get_bike_refinance_financier = get_refinance_financier.filter(e => e.partnership_type.refinance === "Branch Partnership")
            let final_ho_get_bike_refinance_financier = ho_get_bike_refinance_financier.map((obj) => ({
                "key": "HO Partnership",
                label: obj.name,
                value: obj.id
            }))

            let final_branch_get_bike_refinance_financier = branch_get_bike_refinance_financier.map((obj) => ({
                "key": "Branch Partnership",
                label: obj.name,
                value: obj.id
            }))

            let final_get_bike_refinance_financier = [
                {
                    options: final_ho_get_bike_refinance_financier,
                    "label": "HO Partnership"
                },
                {
                    options: final_branch_get_bike_refinance_financier,
                    "label": "Branch Partnership"
                }
            ]

            let get_finance_financier = response[2].data.data
            let final_get_finance_financier = get_finance_financier.map((obj) => ({
                "key": "HO Partnership",
                label: obj.name,
                value: obj.id
            }))

            get_config.ucrf_loanbox_financier = final_get_refinance_financier
            get_config.ubrf_financier = final_get_bike_refinance_financier
            get_config.ucf_financier = final_get_finance_financier
            setConfigList(get_config)

        })
        return get_config
    }

    const handleChange = async (name, option, index) => {
        let tempProgressionData = [...progression_list]
        let element = tempProgressionData[index]
        if (name === "application_type" || name === "ucf_financier" || name === "ubrf_financier" || name === "ucrf_loanbox_financier") {
            tempProgressionData[index][name] = option
        } else if (name === "user_level_tagging" || name === "description" || name === "status") {
            if (name === "status") {
                await updateProgressionStatus(element, option)
                hideModalpopup("ModifyStatusPopup")
            }
            tempProgressionData[index][name] = option
        } else if(name === "role_id"){
            tempProgressionData[index][name] = parseInt(option.value)
        } else {
            if(name === "month"){
                tempProgressionData[index].year = option.year
            }
            tempProgressionData[index][name] = option.value
        }
        if (name === "role_id") {
            setLoading(true)
            await getConfigList(option.value, tempProgressionData, index)
        } else {
            setProgressionList(tempProgressionData)
        }
    }

    const updateProgressionStatus = async (element, value) => {
        setLoading(true)
        let params = {
            id: element.id,
            status: value
        }
        MasterService.post('/sfa/user/update-progression-data', params)
            .then(function (response) {
                if (response.data.status === 200) {
                    getProgressionList()
                } else {
                    setLoading(false)
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
    }

    const checkExistingData = async (element, index) => {
        setLoading(true)
        let data_existing_status = false
        await MasterService.post('/sfa/user/check-existing-progression', element)
            .then(function (response) {
                if (response.data.status === 200) {
                    if (response.data.data.data_exist) {
                        data_existing_status = true
                    }
                } else {
                    setLoading(false)
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
        return data_existing_status
    }

    const validUpdateForm = (data, i) => {
        let formIsValid = true;
        let tempErrors = {};
        if (!data["role_id"]) {
            formIsValid = false;
            tempErrors["user_role_" + i] = 'User Role is required';
        }
        /* if (!data["business_line"]) {
            formIsValid = false;
            tempErrors["business_line_" + i] = 'Business Line is required';
        } */
        if (!data["month"]) {
            formIsValid = false;
            tempErrors["month_" + i] = 'Month is required';
        }
        if (data["application_type"].length === 0) {
            formIsValid = false;
            tempErrors["application_type_" + i] = 'Application Type is required';
        } else {
            let ucf_financier = data.application_type.filter(e => e.value === "UCF")
            let ucrf_loanbox_financier = data.application_type.filter(e => e.value === "UCRF")
            let ubrf_financier = data.application_type.filter(e => e.value === "UBRF")
            if (ucf_financier.length && (!data["ucf_financier"] || data["ucf_financier"].length === 0)) {
                formIsValid = false;
                tempErrors["ucf_financier_" + i] = 'UCF Financier is required';
            }
            if (ucrf_loanbox_financier.length && (!data["ucrf_loanbox_financier"] || data["ucrf_loanbox_financier"].length === 0)) {
                formIsValid = false;
                tempErrors["ucrf_financier_" + i] = 'UCRF Financier is required';
            }
            if (ubrf_financier.length && (!data["ubrf_financier"] || data["ubrf_financier"].length === 0)) {
                formIsValid = false;
                tempErrors["ubrf_financier_" + i] = 'UBRF Financier is required';
            }
        }
        if (data.user_level_tagging && data.user_level_tagging.length) {
            data.user_level_tagging.forEach((element, index) => {
                if (!element["m1_lead_count"]) {
                    formIsValid = false;
                    tempErrors["m1_lead_count_" + i + "_" + index] = 'm1_lead_count is required';
                }
                if (!element["m2_lead_count"]) {
                    formIsValid = false;
                    tempErrors["m2_lead_count_" + i + "_" + index] = 'm2_lead_count is required';
                }
                if (!element["operator1"]) {
                    formIsValid = false;
                    tempErrors["operator1_" + i + "_" + index] = 'Operator is required';
                }
                if (!element["operator2"]) {
                    formIsValid = false;
                    tempErrors["operator2_" + i + "_" + index] = 'Operator is required';
                }
            });
        }
        setTempErrors(tempErrors)
        return formIsValid;
    }

    const handleUpdate = async (data, index) => {
        if (validUpdateForm(data, index)) {
            let data_exist = await checkExistingData(data, index)
            if (data_exist) {
                toast.error("Data Already Exist With Same Role, Business Line And Month");
                setLoading(false)
            } else {
                let user_level_tagging_modified = data.user_level_tagging.map((obj) => ({
                    user_tagging_id: obj.value,
                    m1_lead_count: obj.m1_lead_count,
                    operator1: obj.operator1.value,
                    m2_lead_count: obj.m2_lead_count,
                    operator2: obj.operator2.value,
                    status: '1'
                }))
                let application_type_modified = data.application_type.map(({ value }) => value)
                let ucf_financier_modified = data.ucf_financier ? data.ucf_financier.map(({ value }) => value) : []
                let ucrf_financier_modified = data.ucrf_loanbox_financier ? data.ucrf_loanbox_financier.map(({ value }) => value) : []
                let ubrf_financier_modified = data.ubrf_financier ? data.ubrf_financier.map(({ value }) => value) : []

                ucf_financier_modified = ucf_financier_modified.filter(e => e !== "*")
                ucrf_financier_modified = ucrf_financier_modified.filter(e => e !== "*")
                ubrf_financier_modified = ubrf_financier_modified.filter(e => e !== "*")
                let params = {
                    id: data.id,
                    role_id: data.role_id,
                    /* business_line: data.business_line, */
                    month: data.month,
                    year: data.year,
                    application_type: application_type_modified.length ? application_type_modified.join(',') : '',
                    ucf_financier: ucf_financier_modified.length ? ucf_financier_modified.join(',') : '',
                    ucrf_loanbox_financier: ucrf_financier_modified.length ? ucrf_financier_modified.join(',') : '',
                    ubrf_financier: ubrf_financier_modified.length ? ubrf_financier_modified.join(',') : '',
                    description: data.description,
                    user_level_tagging: user_level_tagging_modified,
                    status: data.status
                }
                if (data.id) {
                    params.id = data.id
                }
                MasterService.post('sfa/user/save-progression-data', params)
                    .then(function (response) {
                        if (response.data.status === 200) {
                            toast.success(response.data.message);
                            getProgressionList()
                        } else {
                            toast.error(response.data.message);
                        }
                    })
                    .catch(function (response) {
                        setLoading(false)
                        toast.error(response);
                    });
            }
        }
    }

    const handleCancel = (element, index) => {
        let tempProgressionData = [...progression_list]
        if (element.id) {
            getProgressionList()
        } else {
            tempProgressionData.splice(index, 1);
            setProgressionList(tempProgressionData)
        }
    }

    const handleAddProgression = () => {
        let tempProgressionData = [...progression_list]
        let tempAllProgressionData = [...progressionAllData]
        let progression_data = {
            role_id: "",
            application_type: [],
           /*  business_line: "", */
            month: "",
            description: "",
            status: 1,
            preExpandedTabs:0
        };

        tempProgressionData.unshift(progression_data)
        setProgressionList(tempProgressionData)
        setProgressionAllData(tempAllProgressionData)
    };

    const showExpandedTabUuidTabs = (uuid, id) => {
        let tempProgressionData = [...progressionAllData]

        let temp_data = tempProgressionData.findIndex(e=>e.id === id)
        tempProgressionData[temp_data].preExpandedTabs = uuid
        setProgressionAllData(tempProgressionData)
    }


    return (
        <React.Fragment>
            <div className={loading ? 'container-fluid loading' : 'container-fluid'}>


                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="tab-line white-bg p-l-r-15 minus-l-r-15 hading-tab-top">
                        <div className="top-heading">
                            <h1>Add Progression</h1>
                            <Nav variant="pills" className="flex-column">
                                <div className="tab-list">
                                    <Nav.Item onClick={() => { setActiveTab(1) }} >
                                        <Nav.Link eventKey="first">Active Progression</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => { setActiveTab(2) }} >
                                        <Nav.Link eventKey="second">Expired Progression</Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>
                        </div>
                        {activeTab === 1 && (
                            <button onClick={() => { handleAddProgression() }} className="btn-line btn-add-incentive" >
                                <i className="ic-add"></i>
                                <span>Add Progression</span>
                            </button>
                        )}
                    </div>

                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            {progression_list.length > 0 && Object.keys(config).length > 0 ? (
                                <AddProgression
                                    activeTab={activeTab}
                                    progression_list={progression_list}
                                    tempErrors={tempErrors}
                                    handleChange={handleChange}
                                    handleUpdate={handleUpdate}
                                    handleCancel={handleCancel}
                                    configList={configList}
                                    config={config}
                                    showExpandedTabUuid={showExpandedTabUuidTabs}
                                    showModalpopup={showModalpopup}
                                />
                            ) : (
                                <div className="active-scheme-outer">
                                    <div className="white-bg">
                                        <span>No Progression Found</span>
                                        <button
                                            className="btn-primary"
                                            onClick={() => { handleAddProgression() }}
                                        >
                                            Add Progression
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            {expired_progression_list.length > 0 ? (
                                <AddProgression
                                    activeTab={activeTab}
                                    progression_list={expired_progression_list}
                                    tempErrors={tempErrors}
                                    handleChange={handleChange}
                                    handleUpdate={handleUpdate}
                                    handleCancel={handleCancel}
                                    configList={configList}
                                    config={config}
                                    showExpandedTabUuid={showExpandedTabUuidTabs}
                                    showModalpopup={showModalpopup}
                                />
                            ) : (
                                <div className="active-scheme-outer">
                                    <div className="white-bg">
                                        <span>No Expired Progression Found</span>
                                    </div>
                                </div>
                            )}
                        </Tab.Pane>

                    </Tab.Content>

                </Tab.Container>
                <div className="delete-incentive-popup model-popup-outer">
                    <Modal show={DeleteProgressionPopup} handleClose={() => { hideModalpopup("DeleteProgressionPopup") }} >
                        <div className="modal-header">

                        </div>
                        <div className="modal-body">
                            <div className='delete-popup-txt'>
                                <span>Are you sure you want to delete this progression</span>
                                <div className='yes-no-btn'>
                                    <button onClick={() => { hideModalpopup("DeleteProgressionPopup") }} className='btn-line'>No</button>
                                    <button onClick={() => { handleDeleteYes() }} className='btn-primary'>yes</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
                {/* Modal for status confirmation of incentive */}
                <div className="delete-incentive-popup model-popup-outer">
                    <Modal show={ModifyStatusPopup} handleClose={() => { hideModalpopup("ModifyStatusPopup") }}>
                        <div className="modal-header">

                        </div>
                        <div className="modal-body">
                            <div className='delete-popup-txt'>
                                {/* <img src={delete_img} className="" alt='' /> */}
                                <span>Are you sure you want to inactive this progression</span>
                                <div className='yes-no-btn'>
                                    <button onClick={() => { hideModalpopup("ModifyStatusPopup") }} className='btn-line'>No</button>
                                    <button onClick={() => { handleChange("status", 2, selected_index) }} className='btn-primary'>yes</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>

            </div>
        </React.Fragment>
    )
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};
export default ProgressionList;