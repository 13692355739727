import React, {  useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { defaultZone, EXECUTIVE_ROLES, VISIT_EXECUTIVE_ROLES, Zone } from '../../config/constant';
import { MasterService } from '../../services';
import { Calendra, Calendra2 } from './DatePicker'
import { getFirstDate, sort } from '../../config/helper';
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const VisitTypeList = [
    { value: '', label: 'All Visit' },
    { value: 'D', label: 'Dealer' },
    { value: 'C', label: 'Customer' },
    { value: 'ND', label: 'New Dealer' },

]
const statusOptions = [
    { value: 'All', label: 'All' },
    { value: '1', label: 'Completed' },
    { value: '0', label: 'Pending' },
]

const VisitReportTopFilter = (props) => {
    // const [zoneValue, setZone] = useState(defaultZone)
    const [roleList, setRoleList] = useState([])
    const [roleSelected, setRole] = useState(null);
    const [cityList, setCityList] = useState([])
    const [stateList, setStateList] = useState([])
    const [city, setCity] = useState([]);
    const [state, setState] = useState(null);
    const [fromDate, setFromDate] = useState(getFirstDate(new Date()));
    const [toDate, setToDate] = useState(new Date());
    const [visitType, setVisitType] = useState(null);
    const [statusType, setStatusType] = useState(null);
    const [executiveData, setExecutiveData] = useState(null);
    const [dealerData, setDealerData] = useState(null);
    const [executive, setExecutive] = useState(null);
    const [dealer, setDealer] = useState(null);


    useEffect(() => {
        getRoleList()
        getStateList()
    }, [])

    useEffect(() => {
        if (props.isReset) {
            handleReset()
        }
    }, [props.isReset])

    useEffect(() => {
        if (props.type === "dealer") {
            getDealerListing()
        } else {
            getExecutiveListing()
        }
    }, [city, roleSelected])

    const getStateList = async () => {
        MasterService.post('/sfa/user/state_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map(elm => ({ id: elm.id, label: elm.state_name, value: elm.id }));
                    setStateList(state_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getCityList = async (state_ids) => {
        MasterService.post('/sfa/user/city_list', { state_ids })
            .then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                    setCityList(city_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    if (props.type === "dealer") {
                        let role_list = response.data.data.filter(e => EXECUTIVE_ROLES.includes(e.id))
                        role_list = role_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                        setRoleList(role_list)
                    }
                    if (props.type === "executive") {
                        let role_list = response.data.data.filter(e => VISIT_EXECUTIVE_ROLES.includes(e.id))
                        role_list = role_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                        setRoleList(role_list)
                    }
                }
            })
            .catch(function (response) {
            });
    }

    const handleChange = (event, name) => {
        if (name === "zone") {
            // setZone(event.id)
        }
        if (name === "from_date") {
            setFromDate(event)
        }
        if (name === "to_date") {
            setToDate(event)
            if (new Date(event).getTime() <= new Date(fromDate).getTime()) {
                setFromDate(null)
            }
        }
        if (name === "role") {
            setRole(event)
        }
        if (name === "state") {
            setState(event)
            setCity([])
            getCityList([event.id], true)
        }
        if (name === "city") {
            setCity(event)
            setExecutive(null)
            setDealer(null)
        }
        if (name === "visit_type") {
            setVisitType(event)
        }
        if (name === 'status') {
            setStatusType(event)
        }
        if (name === 'executive') {
            setExecutive(event)
        }
        if (name === 'dealer') {
            setDealer(event)
        }
    };

    const getExecutiveListing = async () => {
        let searchData = {
            search_text: '',
            city_id: city ? [city.id] : '',
            state_id: state ? state.id : '',
            apply_limit: "no",
            vertical_id: 1,
            page: 1,
            role_id: roleSelected ? roleSelected.id : '',
        }
        await MasterService.post('/sfa/dealer/executiveListByCities', searchData)
            .then(function (response) {
                if (response.data.status === 200) {
                    let executive_names = response.data.data.executive_data.map((e) => ({
                        label: e.name,
                        id: e.id,
                        value: e.id,
                        user_id: e.id
                    }))
                    executive_names = sort(executive_names, 'label')
                    setExecutiveData(executive_names);
                } else {
                    if (response.data.error && response.data.error.length > 0) {
                        response.data.error.forEach(element => {
                            toast.error(element);
                        });
                    } else {
                        toast.error(response.data.message);
                    }
                }
            })
            .catch(function (response) {
            });
    }

    const getDealerListing = async () => {
        let searchData = {
            zone: defaultZone,
            city_ids: city ? [city.id] : '',
            state_id: state ? state.id : '',
        }
        await MasterService.post('/sfa/dealer/dealerListByCities', searchData)
            .then(function (response) {
                if (response.data.status === 200) {
                    let dealer_names = response.data.data.dealer_data.map((e) => ({
                        label: e.organization,
                        dealer_code: e.dealer_id,
                        id: e.dealer_id,
                        value: e.dealer_id
                    }))
                    dealer_names = sort(dealer_names, 'label')
                    setDealerData(dealer_names);
                } else {
                    if (response.data.error && response.data.error.length > 0) {
                        response.data.error.forEach(element => {
                            toast.error(element);
                        });
                    } else {
                        toast.error(response.data.message);
                    }
                }
            })
            .catch(function (response) {
            });
    }

    const handleChangeMultiSel = (field_name, field_obj, event) => {

        let selected_value = []
        if (event.action === "select-option" && event.option.value === "*") {
            selected_value = [{ label: "Select All", value: "*" }, ...cityList]
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            selected_value = []
        } else if (event.action === "deselect-option" && event.option.value !== "*") {
            selected_value = field_obj.filter((o) => o.value !== "*")
        } else if (field_name === "city" && field_obj.length === cityList.length) {
            selected_value = [{ label: "Select All", value: "*" }, ...cityList]
        } else {
            selected_value = field_obj
        }
        if (field_name === "city") {
            setCity(selected_value)
            setExecutive(null)
            setDealer(null)


        }
    }

    const handleSearch = (is_reset) => {
        if (props.type === "executive") {
            if (is_reset) {
                let params = {
                    from_date: getFirstDate(new Date()),
                    to_date: new Date(),
                    role_id: '',
                    // zone_id: zoneValue,
                    state_list: '',
                    city_list: '',
                    visit_type: '',
                    visit_status: '1',
                    units_id: '2',
                    vertical_id: 1,
                    page: 1,
                    sortby: 'sort_visit_date',
                    order: 'DESC',
                    by_user: '',
                    userId: ''
                }
                props.getVisitData(1, params, true)
            } else {
                let params = {
                    from_date: fromDate,
                    to_date: toDate,
                    role_id: roleSelected ? roleSelected.id : '',
                    // zone_id: zoneValue,
                    state_list: state && state.id ? [state.id] : '',
                    city_list: city && city.id ? [city.id] : '',
                    visit_type: visitType && visitType.value ? visitType.value : '',
                    visit_status: statusType && statusType.value ? statusType.value : '1',
                    units_id: '2',
                    vertical_id: 1,
                    page: 1,
                    sortby: 'sort_visit_date',
                    order: 'DESC',
                    by_user: '',
                    userId: executive && executive.id ? executive.id : ''
                }
                props.getVisitData(1, params, true)
            }
        }
        if (props.type === "dealer") {
            if (is_reset) {
                let params = {
                    from_date: getFirstDate(new Date()),
                    to_date: new Date(),
                    role_id: '',
                    // zone_id: zoneValue,
                    state_list: '',
                    city_list: '',
                    visit_type: '',
                    visit_status: '1',
                    units_id: '2',
                    vertical_id: 1,
                    page: 1,
                    sortby: 'sort_visit_date',
                    order: 'DESC',
                    by_user: '',
                    dealer: ''
                }
                props.getVisitData(1, params, true)
            } else {
                let params = {
                    from_date: fromDate,
                    to_date: toDate,
                    role_id: roleSelected ? roleSelected.id : '',
                    // zone_id: zoneValue,
                    state_list: state && state.id ? [state.id] : '',
                    city_list: city && city.id ? [city.id] : '',
                    visit_type: visitType && visitType.value ? visitType.value : '',
                    visit_status: statusType && statusType.value ? statusType.value : '1',
                    units_id: '2',
                    vertical_id: 1,
                    page: 1,
                    sortby: 'sort_visit_date',
                    order: 'DESC',
                    by_user: '',
                    dealer: dealer && dealer.dealer_code ? dealer.dealer_code : ''
                }
                props.getVisitData(1, params, true)
            }
        }
    }

    const handleReset = () => {
        // setZone(defaultZone)
        setFromDate(getFirstDate(new Date()))
        setToDate(new Date())
        setRole(null)
        setState(null)
        setCity([])
        setCityList([])
        setVisitType(null)
        setStatusType(null)
        setExecutive(null)
        setDealer(null)
        setExecutiveData(null)
        setDealerData(null)
        handleSearch(true)
    }

    return (
        <React.Fragment>
            <div className="visit-report-filter">
                <fieldset className="rangepicker-calendra">
                    <div className="material">
                        <Calendra
                            placeholder={"Date From"}
                            defaultDate={fromDate}
                            endDate={toDate}
                            handleDateChange={(e) => handleChange(e, 'from_date')}
                        />
                    </div>
                </fieldset>
                <fieldset className="rangepicker-calendra">
                    <div className="material">
                        <Calendra2
                            placeholder={"To Date"}
                            startDate={fromDate}
                            defaultDate={toDate}
                            endDate={new Date()}
                            handleDateChange={(e) => handleChange(e, 'to_date')}
                        />
                    </div>
                </fieldset>
                {props.type === "executive" &&
                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={roleSelected}
                                onChange={(e) => handleChange(e, 'role')}
                                options={roleList}
                                placeholder="Select Role"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                }
                {/* <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            options={Zone}
                            onChange={(e) => handleChange(e, 'zone')}
                            placeholder="Zone"
                            className="react-select"
                            classNamePrefix="react-select"
                            value={Zone.filter(e => e.id === zoneValue)}
                        />
                    </div>
                </fieldset> */}
               
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            options={stateList}
                            onChange={(e) => handleChange(e, 'state')}
                            placeholder="Select State"
                            className="react-select"
                            classNamePrefix="react-select"
                            value={state}
                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            options={cityList}
                            onChange={(e) => handleChange(e, 'city')}
                            placeholder="Select City"
                            className="react-select"
                            classNamePrefix="react-select"
                            value={city}
                        />
                    </div>
                </fieldset>
                {props.type === "executive" &&
                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={visitType}
                                onChange={(e) => handleChange(e, 'visit_type')}
                                options={VisitTypeList}
                                placeholder="Visit Type"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                }

                <fieldset className="single-select">
                    <div className="material">
                        <Select components={{ ValueContainer: CustomValueContainer }}
                            value={statusType}
                            onChange={(e) => handleChange(e, 'status')}
                            options={statusOptions}
                            placeholder="Status"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>
                <fieldset className="single-select">
                    <div className="material">
                        {props.type === "dealer" ?
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={dealer}
                                isDisabled={(city && city.id ) ? false : true}
                                onChange={(e) => handleChange(e, 'dealer')}
                                options={dealerData}
                                placeholder="Select Dealer"
                                className="react-select"
                                classNamePrefix="react-select"
                            /> : <Select components={{ ValueContainer: CustomValueContainer }}
                                value={executive}
                                isDisabled={(city && city.id ) ? false : true}
                                onChange={(e) => handleChange(e, 'executive')}
                                options={executiveData}
                                placeholder="Select Executive"
                                className="react-select"
                                classNamePrefix="react-select"
                            />}
                    </div>
                </fieldset>
                <div class="btn-submit-reset">
                    <button type="submit" class="btn-primary" onClick={() => { handleSearch(false) }}>Search</button>
                    <button class="btn-reset" onClick={() => handleReset()}>Reset</button>
                </div>
            </div>


        </React.Fragment>
    )
    // }
}
export default VisitReportTopFilter;