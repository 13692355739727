import React, { useEffect, useState } from 'react';
import { Nav, Tab, ToggleButton } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import { Zone, BasicDetailConfig, Role, validationForFields, imageWidth, EMAIL_VALIDATION, defaultZone, EmploymentType, ESIGN_BASE_URL, VP_ID } from '../../config/constant';
import MasterService from '../../services/MasterService';
import DateFormat from 'dateformat';
import ViewMappedRegions from './ViewMappedRegions';
import ViewReportees from './ViewReportees';
import SimpleSlider from './uploadDocSlider';
import { DealerService } from '../../services';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
import HierarchyDetails from './HierarchyPopUp';
import HierarchyIcon from "../../webroot/images/hierarchy_icon.png";
import InputField from '../common/Input';
import NumberFormat from 'react-number-format';
import secureStorage from './../../config/encrypt';
import Tooltip from 'react-tooltip-lite';
const ViewUser = (props) => {
    let { user_id } = useParams()
    // let { stateList, cityList } = props
    const [loading, setLoading] = useState(false);
    const [cityList, setCityList] = useState([])
    const [localityList, setLocalityList] = useState([])
    const [stateList, setStateList] = useState([])
    const [userDetail, setUserDetail] = useState({})
    const [roleList, setRoleDetails] = useState([])
    const [showEdit, setshowEdit] = useState(false)
    const [activeClass, setActiveClass] = useState(false)
    const [reportingRoleList, setReportingRoleList] = useState([])
    const [existing_errors, SetExistingErrors] = useState({});
    const [is_existing, setIsExisting] = useState(false);
    const [ReferralName, setReferralName] = useState([])
    const [ConfigList, setConfigList] = useState([])
    const [reportingManagerList, setReportingManagerList] = useState([])
    const [bankList, setBankList] = useState([])
    const [bankOption, setBankOption] = useState([])
    const [UserHistoryPopup, SetUserHistoryPopup] = useState(false);
    const [UserRoleHistoryList, setUserRoleHistoryList] = useState([]);
    const [UserAccountHistoryList, setUserAccountHistoryList] = useState([]);
    const [VendorHistoryList, setVendorHistoryList] = useState([]);
    const [ProgressionHistoryList, setProgressionHistoryList] = useState([]);
    const [showMappedRegions, setShowMappedRegions] = useState(false);
    const [showReportees, setShowReportees] = useState(false);
    const [show_bank_detail, setShowBankDetail] = useState(false);
    const [show_sss_detail, setShowSSSDetail] = useState(false);
    const [show_image_detail, setShowImageDetail] = useState(false);
    const [doc_index, setDocIndex] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0)
    const [rotated_angle, setRotateAngle] = useState(0);
    const [image_width, setImageWidth] = useState(imageWidth);
    const [show_doc, setShowDoc] = useState(false);
    const [errors, SetErrors] = useState({});
    const { ValueContainer, Placeholder } = components;
    const [userLocationDetail, setUserLocationDetail] = useState({})
    const [showHierarchyDetailsModal, setShowHierarchyDetailsModal] = useState(false);
    const [esignApprovedHTML, setEsignApprovedHTML] = useState([]);
    const [esignUserDoc, setEsignUserDoc] = useState([]);
    const [passwordToShow, setPasswordToShow] = useState('');
    // const [showPassword, setShowPassword] = useState(false);
    const [loginUserInfoTemp, SetLoginUserInfoTemp] = useState(secureStorage.getItem('loginUserInfo'));
    const [account_length, setAccountLength] = useState(0)
    const historyLead = useHistory();
    const OnboardedFrom = [
        // { value: 'mobile_app', label: 'App' },
        // { value: 'join_us', label: 'Join Us' },
        { value: 'admin', label: 'SFA Backend' },
    ];
    useEffect(() => {
        window.addEventListener('scroll', () => {
            let activeClass = 'sticky-top';
            if (window.scrollY === 0) {
                activeClass = 'top';
            }
            setActiveClass(activeClass)
        });
        getStateList();
        getCityList();
        getUserDetail(user_id);
        getUserLocationDetail(user_id);
    }, [])

    // useEffect(() => {
    //     if (userDetail?.password_text) {
    //         if (showPassword) {
    //             setPasswordToShow(userDetail.password_text)
    //         } else {
    //             setPasswordToShow(userDetail.password_text.replace(/./g, '*'))
    //         }
    //     }

    // }, [showPassword, userDetail])

    const getStateList = async () => {
        MasterService.post('/sfa/user/state_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map(elm => ({ id: elm.id, label: elm.state_name, value: elm.id }));
                    setStateList(state_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getCityList = async () => {
        MasterService.post('/sfa/user/city_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id }));
                    setCityList(city_list);
                }
            })
            .catch(function (response) {
            });
    }
    const getUserLocationDetail = async (user_id) => {
        await MasterService.post('/sfa/user/get-user_location', { user_id }).then(function (response) {
            if (response.data.status === 200) {
                let data = response.data.data
                let tempuUserDetail = { ...userLocationDetail };
                let state_data = data.location_response.map(({ state_id }) => state_id)
                state_data = state_data.filter((item, i, ar) => ar.indexOf(item) === i);

                let city_data = data.location_response.map(({ city_id }) => city_id)
                city_data = city_data.filter((item, i, ar) => ar.indexOf(item) === i);
                tempuUserDetail.state_data = state_data
                tempuUserDetail.city_data = city_data
                setUserLocationDetail(tempuUserDetail)
            }
        })
    }
    const getUserDetail = async (user_id) => {
        setLoading(true)
        await MasterService.post('/sfa/user/get-view-detail', { user_id }).then(async function (response) {
            if (response.data.status === 200 && response.data.data) {
                getReferralUserList()
                getRoleList(response.data.data)
                let config_list = await getConfigList(response.data.data)
                getReportingRole(response.data.data)
                getUsersByRole(response.data.data)
                getBankList(response.data.data)
                let data = response.data.data


                if (data.document_response && data.document_response.length > 0) {
                    let documents = []
                    for (let index = 0; index < config_list.document.length; index++) {
                        const elm = config_list.document[index];
                        let doc_url = ""
                        let doc_original_url = ""
                        let file_index = data.document_response.findIndex(e => parseInt(e.document_id) === parseInt(elm.id))
                        if (file_index >= 0) {
                            setShowDoc(true)
                            doc_url = data.document_response[file_index].doc_url
                            doc_original_url = data.document_response[file_index].doc_original_url ? data.document_response[file_index].doc_original_url : ""
                        }
                        let new_doc = {
                            doc_bg_color: elm.doc_bg_color,
                            doc_logo_url: elm.doc_logo_url,
                            doc_max: elm.doc_max,
                            doc_min: elm.doc_min,
                            doc_required: elm.doc_required,
                            doc_title: elm.doc_title,
                            doc_url: doc_url,
                            doc_original_url: doc_original_url,
                            document_id: elm.id
                        }
                        documents.push(new_doc)
                    }
                    data.document_response = documents
                } else {
                    var documents = config_list.document.map(elm => ({
                        created_date: elm.created_date,
                        doc_bg_color: elm.doc_bg_color,
                        doc_logo_url: elm.doc_logo_url,
                        doc_max: elm.doc_max,
                        doc_min: elm.doc_min,
                        doc_required: elm.doc_required,
                        doc_title: elm.doc_title,
                        doc_url: "",
                        doc_original_url: "",
                        document_id: elm.id,
                    }));
                    data.document_response = documents
                }

                await MasterService.getLocalityList(data.user_city).then((res) => {
                    let locality_list = res.data.data.map(elm => ({ id: elm.id, label: elm.value, value: elm.id }));
                    setLocalityList(locality_list)
                })
                if (data.bank_response && data.bank_response.id) {
                    data.bank_response.confirm_account_number = data.bank_response.account_number
                }
                if(data.status=='5'){
                    setshowEdit(false);
                }else{
                    setshowEdit(true); 
                }
                setUserDetail(data)
                let tempEsignApprovedHTML = response?.data?.data?.esignHTMLData ? response?.data?.data?.esignHTMLData : [];
                let tempEsignApprovedDoc = response?.data?.data?.esignUserDoc ? response?.data?.data?.esignUserDoc : [];
                setEsignApprovedHTML(tempEsignApprovedHTML);
                setEsignUserDoc(tempEsignApprovedDoc)
                setLoading(false)
            } else {
                toast.error("Invalid request")
                historyLead.push("/user-management-detail")
            }
        })
    }

    const getReferralUserList = async () => {
        MasterService.post('/sfa/user/get_referral_user', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var referral_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name + " (" + elm.id + ")", value: elm.id }));
                    setReferralName(referral_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getBankList = async (data) => {
        MasterService.post('/sfa/user/bank-list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var bank_list = response.data.data.filter(elm => elm.bankId === data.bank_response.sfa_bank_id);
                    bank_list = bank_list.map(elm => ({ id: elm.bankId, label: elm.bankName, value: elm.bankId, valid_length: elm.valid_length }));
                    var bank_list_option = response.data.data.map(elm => ({ id: elm.bankId, label: elm.bankName, value: elm.bankId, valid_length: elm.valid_length }));
                    setBankOption(bank_list_option)
                    setBankList(bank_list);
                    setAccountLength(bank_list[0].valid_length)
                }
            })
            .catch(function (response) {
            });
    }

    const getRoleList = async (data) => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    let role_list = response.data.data.filter(elm => elm.id === data.role_id);
                    setRoleDetails(role_list)
                }
            })
            .catch(function (response) {
            });
    }

    const getConfigList = async (data) => {
        let config_list 
        await MasterService.post('/sfa/user/get-config', { role_id: data.role_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    setConfigList(response.data.data)
                    config_list = response.data.data
                }
            })
            .catch(function (response) {
            });
            return config_list
    }

    const getUsersByRole = async (data) => {
        if (data.reporting_response.length > 0) {
            MasterService.post('/sfa/user/get-users', { role_id: data.reporting_response[0].manager_role_id })
                .then(function (response) {
                    if (response.data.status === 200) {
                        let reporting_manager_list = response.data.data.user_list.filter(elm => elm.id === data.reporting_response[0].manager_id);
                        setReportingManagerList(reporting_manager_list)
                    }
                })
                .catch(function (response) {
                });
        } else {
            setReportingManagerList([])
        }
    }
    const getReportingRole = async (data) => {
        if (data.reporting_response.length > 0) {
            MasterService.post('/sfa/user/reporting_role_list', { role_id: data.role_id })
                .then(function (response) {
                    if (response.data.status === 200) {
                        let reporting_role_list = response.data.data.filter(elm => elm.id === data.reporting_response[0].manager_role_id);
                        setReportingRoleList(reporting_role_list)
                    }
                })
                .catch(function (response) {
                });
        } else {
            setReportingRoleList([])
        }
    }

    const handleBack = async () => {
        historyLead.push('/user-management-detail');
    }

    const handleEdit = (pathname) => {
        historyLead.push(pathname, { user_id });
    }

    const showHierarychyDetails = () => {
        setShowHierarchyDetailsModal(true);
        document.body.classList.add("overflow-hidden");
    }
    const hideHierarychyDetails = () => {
        setShowHierarchyDetailsModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    const handleDownload = async (ele) => {
        setLoading(true);
        let pdfData =  await MasterService.download('/sfa/user/download_esign', {esign_doc_id : ele.id}, 'arraybuffer')
        const blob = new Blob([pdfData.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Esign_Agreement.pdf';
         document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    }

    const isValidate = (action) => {
        let tempUserDetail = { ...userDetail };
        let tempUserLocationDetail = { ...userLocationDetail };
        let formIsValid = true;
        let tempErrors = {};
        let role_error = "false"
        let basic_detail_error = "false"
        let document_error = "false"

        if (!tempUserLocationDetail["state_data"] || !tempUserLocationDetail["state_data"].length > 0) {
            formIsValid = false;
            role_error = "true";
            tempErrors["state"] = 'State is required';
        }
        if (!tempUserLocationDetail["city_data"] || !tempUserLocationDetail["city_data"].length > 0) {
            formIsValid = false;
            role_error = "true";
            tempErrors["city"] = 'City is required';
        }
        if (!tempUserDetail["role_id"]) {
            formIsValid = false;
            role_error = "true";
            tempErrors["role"] = 'Role is required';
        }
        if (reportingRoleList.length === 0 || !reportingRoleList[0].name) {
            formIsValid = false;
            role_error = "true";
            tempErrors["reporting_role"] = 'Reporting Role is required';
        }
        if (reportingManagerList.length === 0 || !reportingManagerList[0].name) {
            formIsValid = false;
            role_error = "true";
            tempErrors["reporting_manager"] = 'Reporting Manager is required';
        }

        if (!tempUserDetail["name"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["username"] = 'Username is required';
        }
        if (!tempUserDetail["mobile"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["mobile_no"] = 'Mobile is required';
        } else if (tempUserDetail["mobile"].length < 9) {
            formIsValid = false;
            tempErrors["mobile_no"] = 'Mobile length must be between 9 and 12';
        }
        else if (tempUserDetail["mobile"].length > 12) {
            formIsValid = false;
            tempErrors["mobile_no"] = 'Mobile length must be between 9 and 12';
        }
        if (!tempUserDetail["email"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["email_id"] = 'Email is required';
        } else if (tempUserDetail["email"].match(EMAIL_VALIDATION) == null) {
            tempErrors["email_id"] = 'Email is not valid';
        }


        /* if (userDetail.bank_response && userDetail.bank_response["NPWP"] && userDetail.bank_response["NPWP"].replace(/[^0-9]/g, "").length < 15) {
            tempErrors["NPWP"] = 'NPWP is not valid';
        } */
        if (!tempUserDetail["id_number"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["id_number"] = 'ID is required';
        } else if (isNaN(tempUserDetail["id_number"])) {
            formIsValid = false;
            tempErrors["id_number"] = 'ID Number must be numeric';
        }

        if (!tempUserDetail["doj"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["joining_date"] = 'Joining Date is required';
        }
        /* if (bankList.length === 0 || !bankList[0].label) {
            formIsValid = false;
            tempErrors["bank_id"] = 'Bank Name is required';
        }
        if (userDetail.bank_response && !userDetail.bank_response.branch_name) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["bank_branch_name"] = 'Branch is required';
        }
        if (userDetail.bank_response && !userDetail.bank_response.account_number) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["bank_account_number"] = 'Account Number is required';
        }
        if (userDetail.bank_response && !userDetail.bank_response.beneficiary_name) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["beneficiary_name"] = 'Beneficiary Name is required';
        } */
        // if (!tempUserDetail["dob"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["date_of_birth"] = 'DOB is required';
        // }
        if (!tempUserDetail["user_state"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["state"] = 'State is required';
        }
        if (!tempUserDetail["user_city"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["city"] = 'City is required';
        }
        if (!tempUserDetail["pincode"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["postal_code"] = 'Postal Code is required';
        }
        if (!tempUserDetail["current_occupation_id"] && validationForFields.current_occupation.includes(tempUserDetail.role_id)) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["current_occupation"] = 'Current Occupation is required';
        }
        if (!tempUserDetail["company_name"] && validationForFields.company_name.includes(tempUserDetail.role_id)) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["company_name"] = 'Company Name is required';
        }
        if (!tempUserDetail["designation_id"] && validationForFields.designation.includes(tempUserDetail.role_id)) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["designation"] = 'Designation is required';
        }
        /* if (!tempUserDetail["tagging_id"] && validationForFields.tagging.includes(tempUserDetail.role_id)) {
            formIsValid = false;
            tempErrors["tagging_id"] = 'Tagging is required';
        } */
        if (!tempUserDetail["source_id"] && validationForFields.source.includes(tempUserDetail.role_id)) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["source"] = 'Source is required';
        }
        if (!tempUserDetail["business_line"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["business_line"] = 'Business Line is required';
        }
        if (tempUserDetail.document_response.length > 0) {
            for (let index = 0; index < tempUserDetail.document_response.length; index++) {
                const element = tempUserDetail.document_response[index];
                let config_doc = ConfigList.document.filter(e => parseInt(e.id) === parseInt(element.document_id))
                if (element.doc_url === "" && config_doc[0].doc_required) {
                    formIsValid = false
                    document_error = "true"
                    let doc_title = config_doc[0].doc_title
                    tempErrors[doc_title] = doc_title + " is required"
                }
            }
        } else {
            formIsValid = false
            document_error = "true"
        }

        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        let response = {
            formIsValid: formIsValid,
            basic_detail_error: basic_detail_error,
            tempErrors: tempErrors,
            role_error: role_error,
            document_error: document_error
        }
        return response;
    }

    const handleUpdateUserStatus = async (action, status) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Are you sure you want to ${action} ?`)) {
            setLoading(true)
            if (action === "approve") {
                let result = await isValidate(action)
                if (result.formIsValid) {
                    await approveUser()
                } else {
                    setLoading(false)
                    let pathname = ""
                    if (result.role_error === "true") {
                        pathname = '/edit-user/role_details'
                    } else if (result.basic_detail_error === "true") {
                        pathname = '/edit-user/basic_details'
                    } else if (result.document_error === "true") {
                        pathname = '/edit-user/documents'
                    } else {
                        pathname = '/edit-user/role_details'
                    }
                    historyLead.push(pathname + "?=" + userDetail.id, { tempErrors: result.tempErrors });
                }
            } else if (action === "active") {
                let result = await isValidate(action)
                if (result.formIsValid) {
                    await UpdateStatus(status)
                } else {
                    setLoading(false)
                    let pathname = ""
                    if (result.role_error === "true") {
                        pathname = '/edit-user/role_details'
                    } else if (result.basic_detail_error === "true") {
                        pathname = '/edit-user/basic_details'
                    } else if (result.document_error === "true") {
                        pathname = '/edit-user/documents'
                    } else {
                        pathname = '/edit-user/role_details'
                    }
                    historyLead.push(pathname + "?=" + userDetail.id, { tempErrors: result.tempErrors });
                }
            } else if (action === "inactive") {
                // check loan pending and mapping exist
                let params = {
                    user_id,
                    role_id: userDetail.role_id
                }
                let ready_to_inactivate = false
                let ready_to_inactivate_lead = false
                let ready_to_inactivate_reportee = false
                let ready_to_inactivate_loan = false
                let pending_loan_res
                let pending_lead_res
                // if (parseInt(userDetail.role_id) !== parseInt(VP_ID)) {
                //     params.user_id =  parseInt(params.user_id);
                //     // pending_loan_res = await DealerService.getUserPendingLoans({user_list : [params]})
                //     pending_lead_res = await DealerService.getUserPendingLeads({user_list : [params]})
                // }

                await MasterService.post('/sfa/user/get_reportees', { userId: [userDetail.id] }).then(async function (response) {
                    if (response.data.status === 200) {
                        if (response.data.data.reportee && response.data.data.reportee.length) {
                            toast.error("Please remove the reportees")
                        } else {
                            ready_to_inactivate_reportee = true
                        }
                    }
                })
                if (parseInt(userDetail.role_id) !== parseInt(VP_ID)) {
                    let errArr = {
                        ucrf : [],
                        ubrf : [],
                        ucf : []
                    };
                    let errorLoan = false;
                    let errorLead = false;
                    let isCheckedLoan = false;
                    let isCheckedLead = false;
                    // if (pending_loan_res && pending_loan_res.data && pending_loan_res.data.status === 200) {
                    //     isCheckedLoan = true;
                    //     for (const key of ['UCRF', 'UBRF']) {
                    //         if (pending_loan_res && pending_loan_res.data && pending_loan_res.data.data[key]) {
                    //             for (const item of pending_loan_res.data.data[key]) {
                    //                 for (const [k, v] of Object.entries(item['validation'])) {
                    //                     if (v === 1) {
                    //                         errorLoan = true;
                    //                         errArr[`${key.toLowerCase()}`].push(k);
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }

                    // if (pending_lead_res && pending_lead_res.data && pending_lead_res.data.status === 200) {
                    //     isCheckedLead = true;
                    //     if (pending_lead_res && pending_lead_res.data && pending_lead_res.data.data) {
                    //         for (const item of pending_lead_res.data.data) {
                    //             for (const [k, v] of Object.entries(item['validation'])) {
                    //                 if (v === 1) {
                    //                     errorLead = true;
                    //                     errArr['ucf'].push(k);
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }

                    if (errArr.ucrf.length !== 0 || errArr.ubrf.length !== 0 || errArr.ucf.length !== 0) {
                        for(const [k,v] of Object.entries(errArr)){
                            if(v.length !== 0){
                                toast.error(`Error: ${v.join(',')} in ${k.toUpperCase()}`)
                            }
                        }
                    }

                    // if (!errorLoan && isCheckedLoan) {
                    //     ready_to_inactivate_loan = true
                    // }

                    // if (!errorLead && isCheckedLead) {
                    //     ready_to_inactivate_lead = true
                    // }

                    if (ready_to_inactivate_reportee) {
                        ready_to_inactivate = true
                    }
                } else {
                    if (ready_to_inactivate_reportee) {
                        ready_to_inactivate = true
                    }
                }
                if (ready_to_inactivate) {
                    await UpdateStatus(status)
                } else {
                    setLoading(false)
                }
            } else {
                await UpdateStatus(status)
            }
        }
    }

    const UpdateStatus = async (status) => {
        let role_history = {
            onboard_role_id: userDetail.role_id ? userDetail.role_id : "",
            // tagging_id: userDetail.tagging_id ? userDetail.tagging_id : "",
            status: status,
            user_id: userDetail.id ? userDetail.id : "",
            business_line: userDetail.business_line ? userDetail.business_line : "",
            joining_date: userDetail.doj ? userDetail.doj : ""
        }
        let params = {
            user_id,
            status: status,
            role_history
        }
        // let logged_data = localStorage.getItem("logged_data") ? JSON.parse(localStorage.getItem("logged_data")) : {}
        // if (logged_data.id) {
        //     params.userId = logged_data.id
        // }
        // setLoading(false)
        MasterService.post('/sfa/user/update-user-status', params)
            .then(function (response) {
                setLoading(false)
                if (response.data.status === 200) {
                    getUserDetail(user_id)
                    let message = ""
                    if (status === "0") {
                        message = "User Inactivated Successfully"
                    }
                    if (status === "1") {
                        message = "User Activated Successfully"
                    }
                    if (status === "3") {
                        message = "User Rejected Successfully"
                    }
                    toast.success(message);
                }
            })
            .catch(function (response) {
            });
    }
    const approveUser = async() => {
        setLoading(true)
        let role_history = {
            onboard_role_id: userDetail.role_id ? userDetail.role_id : "",
            // tagging_id: userDetail.tagging_id ? userDetail.tagging_id : "",
            status: "1",
            user_id: userDetail.id ? userDetail.id : "",
            business_line: userDetail.business_line ? userDetail.business_line : "",
            joining_date: userDetail.doj ? userDetail.doj : ""
        }
        let params = {
            user_id,
            role_history
        }
        // let logged_data = localStorage.getItem("logged_data") ? JSON.parse(localStorage.getItem("logged_data")) : {}
        // if (logged_data.id) {
        //     params.userId = logged_data.id
        // }
        MasterService.post('/sfa/user/approve-user-status', params)
            .then(function (response) {
                setLoading(false)
                if (response.data.status === 200) {
                    getUserDetail(user_id)
                    toast.success(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }
    const showModalUserHistoryPopup = async () => {
        await MasterService.post('/sfa/user/user_history_list', { 'user_id': user_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    let tempRoleHistoryList = (response.data && response.data.data && response.data.data.role_history) ? response.data.data.role_history : [];
                    let tempAccountHistoryList = (response.data && response.data.data && response.data.data.account_history) ? response.data.data.account_history : [];
                    let tempVendorHistoryList = (response.data && response.data.data && response.data.data.vendor_history) ? response.data.data.vendor_history : [];
                    let tempProgressionHistoryList = (response.data && response.data.data && response.data.data.progression_history) ? response.data.data.progression_history : [];
                    setUserRoleHistoryList(tempRoleHistoryList);
                    setUserAccountHistoryList(tempAccountHistoryList);
                    setVendorHistoryList(tempVendorHistoryList);
                    setProgressionHistoryList(tempProgressionHistoryList)
                    SetUserHistoryPopup(true);
                    document.body.classList.add("overflow-hidden");
                }
            })
            .catch(function (response) {
            });

    }

    const hideModalUserHistoryPopup = () => {
        SetUserHistoryPopup(false);
        document.body.classList.remove("overflow-hidden");
    }

    const handleMappedRegionsTab = () => {
        setShowMappedRegions(true);
    }

    const handleReporteesTab = () => {
        setShowReportees(true);
    }

    const showDetail = (index) => {
        setShowImageDetail(true)
        setActiveIndex(index)
        setRotateAngle(0)
        setImageWidth(imageWidth)
        document.body.classList.add("overflow-hidden");
    }

    const hideDetail = (value) => {
        setShowImageDetail(false);
        getUserDetail(user_id);
        SetErrors({});
        document.body.classList.remove("overflow-hidden");
    }

    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    const handleRemove = () => {
        let temp_user_detail = { ...userDetail }
        let data = temp_user_detail.document_response[activeIndex]
        data.doc_url = ""
        temp_user_detail.document_response[activeIndex] = data
        setUserDetail(temp_user_detail)
    }

    const handleRotate = () => {
        let temp_rotated_angle = rotated_angle === 360 ? 0 : rotated_angle + 90
        setRotateAngle(temp_rotated_angle)
    }

    const zoomOut = () => {
        let temp_image_width = image_width >= 460 ? (image_width - 100) : image_width
        setImageWidth(temp_image_width)
    }

    const zoomIn = () => {
        let temp_image_width = image_width < 1260 ? (image_width + 100) : image_width
        setImageWidth(temp_image_width)
    }

    const handleChangeImage = (event) => {
        let { files, name } = event.target
        let file_index = ConfigList.document.findIndex(e => e.doc_title === name)
        let filesInfo = Array.from(files);
        if (filesInfo && filesInfo.length) {
            filesInfo.forEach((file, index) => {
                let browse_url = URL.createObjectURL(file);
                let temp_user_detail = { ...userDetail }
                let temp_added_documents = temp_user_detail.document_response[file_index]
                // if()
                temp_added_documents["doc_file"] = file;
                temp_added_documents["doc_url"] = browse_url;
                temp_user_detail.document_response[file_index] = temp_added_documents
                temp_user_detail.bank_document = temp_user_detail.document_response.filter(e => parseInt(e.document_id) === 3 || parseInt(e.document_id) === 4)
                temp_user_detail.sss_document = temp_user_detail.document_response.filter(e => parseInt(e.document_id) === 1 || parseInt(e.document_id) === 2)
                setUserDetail(temp_user_detail);
            });
        }
    }

    const handleReUpload = (value) => {
        let element = document.getElementById("upload-image-doc_" + activeIndex)
        element.click()
    }

    const changeSlide = (cur) => {
        setActiveIndex(cur)
        setRotateAngle(0)
        setImageWidth(imageWidth)
    }

    const handleInputChange = async (target_name, type, event) => {
        let temp_basic_details = { ...userDetail };
        let tempErrors = { ...errors };

        if (target_name === "id_number") {
            temp_basic_details[target_name] = event.target.value;
        } else if (type === "checkbox" || type === "select") {
            if (target_name === "sfa_bank_id") {
                setAccountLength(event.valid_length)
                temp_basic_details.bank_response["account_number"] = "";
                temp_basic_details.bank_response["confirm_account_number"] = "";
                temp_basic_details.bank_response["branch_name"] = "";
                temp_basic_details.bank_response["beneficiary_name"] = "";
                // temp_basic_details.bank_response["NPWP"] = "";

                tempErrors["account_number"] = ""
                tempErrors["confirm_account_number"] = ""
                tempErrors["branch_name"] = ""
                tempErrors["beneficiary_name"] = ""
                // tempErrors["NPWP"] = ""
            }
            temp_basic_details.bank_response[target_name] = event.value;
        } else if (type === "toggle") {
            temp_basic_details.bank_response[target_name] = event;
        } else if (type === "number") {
            tempErrors["NPWP"] = ""
            temp_basic_details.bank_response[target_name] = event.formattedValue;
        } else if (target_name === "beneficiary_name") {
            temp_basic_details.bank_response[target_name] = event.target.value;
        } else {
            temp_basic_details.bank_response[target_name] = event.target.value;
        }
        // if (target_name === "id_number") {
        //     if (isNaN(event.target.value)) {
        //         tempErrors["id_number"] = 'ID Number must be numeric';
        //     } else if (event.target.value.length !== 16) {
        //         tempErrors["id_number"] = 'ID Number must be of 16 digits';
        //     } else {
        //         tempErrors[target_name] = ""
        //     }
        // }
        if (target_name === "id_number") {
            if (event.target.value.length <= 0) {
                tempErrors["id_number"] = 'ID Number is required';
            } else {
                tempErrors[target_name] = ""
            }
        }
        setUserDetail(temp_basic_details);
        SetErrors(tempErrors)
    }

    const uploadImages = async (added_documents) => {
        let document_info = []
        for (let index = 0; index < added_documents.length; index++) {
            let element = added_documents[index]
            let doc_object = {}
            if (element.doc_url.includes("blob")) {
                var formData = new FormData();
                formData.append("upload_type", "sfa_docs");
                formData.append("visit_id", `user/${user_id}`);
                formData.append("images", element.doc_file);
                await MasterService.uploadFilesToS3(formData, {
                    "Content-Type": "multipart/form-data",
                }).then((result) => {
                    if (result.data.status === 200) {
                        doc_object.document_id = element.document_id
                        doc_object.doc_url = result.data.data[0].file_url;
                    }
                    document_info.push(doc_object)
                })
            } else {
                doc_object.document_id = element.document_id
                doc_object.doc_url = element.doc_original_url?element.doc_original_url:element.doc_url
                document_info.push(doc_object)
            }
        }
        return document_info
    }

    const handleUpdate = async () => {
        let value = activeIndex === 0 || activeIndex === 3 ? "id" : "bank"
        let res = await checkValidation(value)
        if (res) {
            if (!is_existing) {
                setLoading(true)
                let params = {}
                let pathname = ""
                if (value === "id") {
                    params.id_number = userDetail.id_number
                    params.user_id = userDetail.id
                    pathname = "/sfa/user/update_id"

                } else if (value === "bank") {
                    params.bank_id = userDetail.bank_response.sfa_bank_id
                    params.user_id = userDetail.id
                    params.bank_account_number = userDetail.bank_response.account_number
                    params.beneficiary_name = userDetail.bank_response.beneficiary_name
                    params.bank_branch_name = userDetail.bank_response.branch_name
                    // params.npwp = userDetail.bank_response.NPWP
                    pathname = "/sfa/user/update_bank_details"

                }
                let modified_documents = await uploadImages(userDetail.document_response)
                params.document_info = modified_documents
                // let logged_data = localStorage.getItem("logged_data") ? JSON.parse(localStorage.getItem("logged_data")) : {}
                // if (logged_data.id) {
                //     params.userId = logged_data.id
                // }
                MasterService.post(pathname, params, {}).then(function (response) {
                    if (response.data.status === 200) {
                        toast.success("Data Updated Successfully")
                        hideDetail(value)
                        getUserDetail(userDetail.id)
                    } else {
                        toast.error(response.data.message)
                        hideDetail(value)
                        setLoading(false)
                    }
                    if (response.data.error && response.data.error.length > 0) {
                        response.data.error.forEach(element => {
                            toast.error(element);
                            hideDetail(value)
                            setLoading(false)
                        });
                    }
                })
            } else {
                let tempErrors = { ...existing_errors }
                SetErrors(tempErrors);
            }
        } else {
            if (is_existing) {
                let tempErrors = { ...existing_errors }
                SetErrors(tempErrors);
            }
        }
    }

    const checkValidation = async (value) => {
        let tempUserDetail = { ...userDetail };
        let formIsValid = true;
        let tempErrors = {};
        let res = await checkExisting("", true, value)
        if (value === "id") {
            if (!tempUserDetail["id_number"]) {
                formIsValid = false;
                tempErrors["id_number"] = 'ID is required';
            } else if (isNaN(tempUserDetail["id_number"])) {
                formIsValid = false;
                tempErrors["id_number"] = 'ID Number must be numeric';
            } else if (tempUserDetail["id_number"].length !== 16) {
                // formIsValid = false;
                // tempErrors["id_number"] = 'ID Number must be of 16 digits';
            }
        } else {
            /* if (userDetail.bank_response && !userDetail.bank_response.sfa_bank_id) {
                formIsValid = false;
                tempErrors["sfa_bank_id"] = 'Bank Name is required';
            }
            if (userDetail.bank_response && !userDetail.bank_response.branch_name) {
                formIsValid = false;
                tempErrors["branch_name"] = 'Branch is required';
            }
            if (userDetail.bank_response && !userDetail.bank_response.account_number) {
                formIsValid = false;
                tempErrors["account_number"] = 'Account Number is required';
            } else if (userDetail.bank_response.account_number.length < account_length && account_length > 0) {
                formIsValid = false;
                tempErrors["account_number"] = 'Format of Account Number must ' + account_length + ' digits';
            } */
            if (userDetail.bank_response.account_number && userDetail.bank_response.account_number.length < account_length && account_length > 0) {
                formIsValid = false;
                tempErrors["account_number"] = 'Format of Account Number must ' + account_length + ' digits';
            }
            /* if (userDetail.bank_response && !userDetail.bank_response.confirm_account_number) {
                formIsValid = false;
                tempErrors["confirm_account_number"] = 'Confirm Account Number is required';
            }
            else if (userDetail.bank_response.confirm_account_number.length < account_length && account_length > 0) {
                formIsValid = false;
                tempErrors["confirm_account_number"] = 'Format of Account Number must ' + account_length + ' digits';
            } else if (!(userDetail.bank_response.account_number.trim() === "" && userDetail.bank_response.confirm_account_number.trim() === "") && userDetail.bank_response.confirm_account_number !== userDetail.bank_response.account_number) {
                formIsValid = false;
                tempErrors["confirm_account_number"] = 'Account Number not matching';
            } */
            if (userDetail.bank_response.confirm_account_number && userDetail.bank_response.confirm_account_number.length < account_length && account_length > 0) {
                formIsValid = false;
                tempErrors["confirm_account_number"] = 'Format of Account Number must ' + account_length + ' digits';
            } else if (!(userDetail.bank_response.confirm_account_number && userDetail.bank_response.account_number.trim() === "" && userDetail.bank_response.confirm_account_number.trim() === "") && userDetail.bank_response.confirm_account_number !== userDetail.bank_response.account_number) {
                formIsValid = false;
                tempErrors["confirm_account_number"] = 'Account Number not matching';
            }
            /* if (userDetail.bank_response && !userDetail.bank_response.beneficiary_name) {
                formIsValid = false;
                tempErrors["beneficiary_name"] = 'Beneficiary Name is required';
            } */
        }


        if (tempUserDetail.document_response.length > 0) {
            for (let index = 0; index < tempUserDetail.document_response.length; index++) {
                const element = tempUserDetail.document_response[index];
                let config_doc = ConfigList.document.filter(e => parseInt(e.id) === parseInt(element.document_id))
                if (element.doc_url === "" && config_doc[0].doc_required) {
                    formIsValid = false
                    let doc_title = config_doc[0].doc_title
                    tempErrors[doc_title] = doc_title + " is required"
                }
            }
        } else {
            formIsValid = false
            tempErrors["Thai ID Card"] = "Thai ID Card is required"
            tempErrors["Salary Certificate"] = "Salary Certificate is required"
            tempErrors["Bank Statement"] = "Bank Statement is required"
            tempErrors["Driving Licence"] = "Driving Licence is required"
        }
        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        if (formIsValid) {
            formIsValid = res
        } else {
            SetErrors(tempErrors);
        }
        return formIsValid;
    }

    const checkExisting = async (event, without_event, value) => {
        let tempErrors = { ...errors };
        let tempExistingErrors = { ...existing_errors };
        let data_exist = false
        let formIsValid = true;
        let call_api = true

        let params = {}
        if (userDetail.id) {
            params.user_id = userDetail.id
        }
        if (without_event) {
            params.id_number = userDetail.id_number ? userDetail.id_number : ""
            // params.npwp = userDetail.bank_response && userDetail.bank_response.NPWP ? userDetail.bank_response.NPWP : ""
            params.bank_account_number = userDetail.bank_response && userDetail.bank_response.account_number ? userDetail.bank_response.account_number : ""
            params.bank_id = userDetail.bank_response && userDetail.bank_response.bank_id ? userDetail.bank_response.bank_id : 0
        } else {
            if (event.target.name === "id_number") {
                params["id_number"] = event.target.value ? event.target.value : event.target.defaultValue
            /* } else if (event.target.name === "NPWP") {
                params["npwp"] = event.target.value ? event.target.value : event.target.defaultValue */
            } else if ((event.target.name === "account_number" || event.target.name === "confirm_account_number") && userDetail.bank_response.account_number && userDetail.bank_response.confirm_account_number) {
                if (userDetail.bank_response.account_number.trim() === userDetail.bank_response.confirm_account_number.trim()) {
                    params.bank_account_number = userDetail.bank_response && userDetail.bank_response.account_number ? userDetail.bank_response.account_number : ""
                    params.bank_id = userDetail.bank_response && userDetail.bank_response.bank_id ? userDetail.bank_response.bank_id : 0
                } else {
                    call_api = false
                }
            } else {
                params[event.target.name] = event.target.value ? event.target.value : event.target.defaultValue
            }
        }
        if (call_api) {
            await MasterService.post('/sfa/user/check_existing', params, {}).then(function (response) {
                if (response.data.status === 200) {
                    let response_data = response.data.data
                    if (value === "id") {
                        if (response_data.id_number) {
                            if (response_data.id_number.is_exist === true) {
                                data_exist = true
                                formIsValid = false
                                tempErrors["id_number"] = response_data.id_number.error_message
                                tempExistingErrors["id_number"] = response_data.id_number.error_message
                            } else {
                                delete tempErrors["id_number"]
                                delete tempExistingErrors["id_number"]
                            }
                        }
                    } else {
                        /* if (response_data.npwp) {
                            if (response_data.npwp.is_exist === true) {
                                data_exist = true
                                formIsValid = false
                                tempErrors["NPWP"] = response_data.npwp.error_message
                                tempExistingErrors["NPWP"] = response_data.npwp.error_message
                            } else {
                                delete tempErrors["NPWP"]
                                delete tempExistingErrors["NPWP"]
                            }
                        } */
                        if (response_data.bank_account_number) {
                            if (response_data.bank_account_number.is_exist === true) {
                                data_exist = true
                                formIsValid = false
                                tempErrors["account_number"] = response_data.bank_account_number.error_message
                                tempExistingErrors["account_number"] = response_data.bank_account_number.error_message
                            } else {
                                delete tempErrors["account_number"]
                                delete tempExistingErrors["account_number"]
                            }
                        }
                    }
                    SetErrors(tempErrors);
                    SetExistingErrors(tempExistingErrors)
                    setIsExisting(data_exist)
                }
            }).catch(function (response) {
            });
            return formIsValid
        } else {
            return formIsValid
        }
    }

    return (
        <React.Fragment>
            <div className='container-fluid'>
                {loading ? <Loader /> : null}
                <div className={`top-bar ${activeClass}`}>
                    <div className="top-heading d-flex">
                        <div><h1>{userDetail?.name}</h1><span>({roleList[0]?.name})</span></div>
                        <div className="right-btn btn-agent-detail">
                            <button className='btn-line m-xs-l' onClick={handleBack.bind(this)}>
                                Back
                            </button>
                            {(([53, 54, 57, 59].includes(userDetail.role_id) || (userDetail.role_id == 52 && userDetail.employe_type == '0')) && (userDetail.status === '0' || userDetail.status === '1')) &&
                                <button className="m-xs-l btn_vendor">Vendor Code : {(userDetail.vendor_id) ? userDetail.vendor_id : <i className="ic-otp" title={(userDetail.add_vendor_failure && userDetail.add_vendor_failure.vendor_id) ? userDetail.add_vendor_failure.vendor_id : 'Vendor Code Not Generated'}>
                                    <i className='path1'></i><i className='path2'></i><i className='path3'></i><i className='path4'></i>
                                </i>} </button>
                            }
                            <button onClick={() => showModalUserHistoryPopup(userDetail.id)} className="btn-line m-xs-l"> <i className="ic-history"></i> History</button>
                            {showEdit &&
                                <button onClick={() => handleEdit("/edit-user/role_details?id=" + user_id)} className="btn-line m-xs-l"><i className="ic-edit"></i> Edit</button>
                            }
                            {(userDetail.status === '0') &&
                                <button onClick={() => handleUpdateUserStatus("active", "1")} className="btn-line m-xs-l">Active</button>
                            }
                            {userDetail.status === '1' &&
                                <button onClick={() => handleUpdateUserStatus("inactive", "0")} className="btn-line m-xs-l">Inactive</button>
                            }
                            {(userDetail.status === '4' || userDetail.status === '3') &&
                                <button onClick={() => handleUpdateUserStatus("approve", "1")} className="btn-line m-xs-l">Approve</button>
                            }
                            {userDetail.status === '4' &&
                                <button onClick={() => handleUpdateUserStatus("reject", "3")} className="btn-line m-xs-l">Reject</button>
                            }
                        </div>
                    </div>
                </div>
                <div className='agent-detail-profile'>
                    <div className='role-detail-preview preview-confirmation'>
                        <div className='profile-photo'>
                            {userDetail.profile_image_path !== "" ?
                                <img src={userDetail.profile_image_path} className="" alt="no pic" /> :
                                <label>No Image</label>
                            }
                        </div>

                    </div>
                    <div className='role-detail-preview d-flex'>
                        <ul>
                            <li>
                                <span>Username</span>
                                <label>{userDetail.name}</label>
                            </li>
                            <li>
                                <span>Status</span>
                                <label>{(userDetail.status === '0') ? 'In-active' : (userDetail.status == '1') ? 'Active' : (userDetail.status == '3') ? 'Rejected' : (userDetail.status == '4') ? 'In-process':''}</label>
                            </li>
                            {(userDetail.role_id == 53) && <li>
                                <span>Current Level</span>
                                <label>{(userDetail.agent_level) ? userDetail.agent_level : 'NA'}</label>
                            </li>
                            }
                            {!([Role['President']].includes(userDetail?.role_id)) && userDetail.allManagerList?.length ? <li>
                                <span>Hierarchy</span>
                                <span className="truncate" title="">{userDetail.bm_name}</span>
                                <span onClick={showHierarychyDetails} title={"Show Hierarchy"}>
                                    <img src={HierarchyIcon} className="hierarchy-icon" alt="" />
                                </span>
                            </li> : null}

                            {
                                showHierarchyDetailsModal === false ? '' : (
                                    <div className='view-timeline-popup image-timeline-popup reporting-line-popup'>
                                        <Modal show={showHierarchyDetailsModal} handleClose={hideHierarychyDetails} style={{ width: "500px", borderRadius: "15px" }}>
                                            <HierarchyDetails allManagerList={userDetail.allManagerList} />
                                        </Modal>
                                    </div>
                                )
                            }
                        </ul>
                    </div>
                </div>
                <div className="agent-detail-tads">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Nav variant="pills" className="flex-column tab-line">
                            <div className="tab-list">
                                <Nav.Item onClick={() => { }}>
                                    <Nav.Link eventKey="first">User Details</Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={handleMappedRegionsTab}>
                                    <Nav.Link eventKey="second"> Mapped Regions</Nav.Link>
                                </Nav.Item>

                                {!([Role['Sales Officer']].includes(userDetail?.role_id)) ? <Nav.Item onClick={handleReporteesTab}>
                                    <Nav.Link eventKey="third">Reportees</Nav.Link>
                                </Nav.Item> : null}

                            </div>

                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey={"first"}>
                                <div className='preview-confirmation add-user-detail-filed'>
                                    <div className='top-heading d-flex'>
                                        <h2>Role Details</h2>
                                        { showEdit &&  
                                            <a onClick={() => handleEdit("/edit-user/role_details?id=" + user_id)} className='edit-opt'>Edit</a>
                                        }
                                    </div>

                                    <div className='d-flex'>
                                        <div className='role-detail-preview'>
                                            <h3>Business Vertical</h3>
                                            <ul>
                                                <li>
                                                    <span>Business</span>
                                                    <label>OTO TH</label>
                                                </li>
                                                <li>
                                                    <span>Vertical</span>
                                                    <label>Used Car</label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='role-detail-preview'>
                                            <h3>Map Region</h3>
                                            <ul>
                                                <li>
                                                    <span>Zone</span>
                                                    <label>{Zone.filter(e => e.id === defaultZone)[0].label}</label>

                                                </li>
                                                {userLocationDetail.state_data &&
                                                    <li>
                                                        <span>State</span>
                                                        <label>{userLocationDetail.state_data ? userLocationDetail.state_data.length : "No"} States</label>
                                                    </li>
                                                }
                                                {userLocationDetail.city_data &&
                                                    <li>
                                                        <span>City</span>
                                                        <label>{userLocationDetail.city_data ? userLocationDetail.city_data.length : "No"} Cities</label>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='d-flex m-xl-t p-sm-t m-xl-b p-md-b'>
                                        <div className='role-detail-preview'>
                                            <h3>Role and Reporting</h3>
                                            <ul>
                                                <li>
                                                    <span>Role</span>
                                                    {roleList && roleList.length > 0 ?
                                                        <label>{roleList[0].name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>Reporting Role</span>
                                                    {reportingRoleList && reportingRoleList.length > 0 ?
                                                        <label>{reportingRoleList[0].name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>Reporting Manager</span>
                                                    {reportingManagerList && reportingManagerList.length > 0 ?
                                                        <label>{reportingManagerList[0].name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <div className='top-heading d-flex'>
                                        <h2>Basic Details</h2>
                                        {userDetail.status != '5' &&  
                                            <a onClick={() => handleEdit("/edit-user/basic_details?id=" + user_id)} className='edit-opt'>Edit</a>
                                        }
                                    </div>

                                    <div className='d-flex'>

                                        <div className='role-detail-preview preview-basic-details'>
                                            <h3>Personal Detail</h3>
                                            <ul>
                                                <li>
                                                    <span>Username</span>
                                                    <label>{userDetail.name ? userDetail.name : "-"}</label>
                                                </li>
                                                <li>
                                                    <span >Mobile</span>
                                                    <label style={{ display: "flex" }}>{userDetail.mobile ? userDetail.mobile : "-"}
                                                        {userDetail.is_mobile_no_verified === "1" ?
                                                            <span className='verify-txt'>Verified</span> : null}
                                                    </label>
                                                </li>
                                                <li>
                                                    <span>Email</span>
                                                    <label title={userDetail.email ? userDetail.email : "-"}>{userDetail.email ? userDetail.email : "-"}</label>
                                                </li>

                                                <li>
                                                    <div>
                                                        <span>ID Number</span>
                                                    </div>
                                                    <label style={{ display: "flex" }}>{userDetail.id_number ? userDetail.id_number : "-"}
                                                        {/* {userDetail.id_number  && showEdit &&
                                                            <span onClick={() => showDetail(0)} className="verify-txt">Verify</span>
                                                        } */}
                                                        </label>
                                                </li>
                                                {/* <li>
                                                    <span>TIN ID (Secondary ID)</span>
                                                    <label>{userDetail.tin_id ? userDetail.tin_id : "-"}</label>
                                                </li> */}
                                                <li>
                                                    <span>Emploment Type</span>
                                                    <label>{EmploymentType.findIndex(e => e.value === userDetail.employe_type) > -1 ? EmploymentType.filter(e => e.value === userDetail.employe_type)[0].label : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>Employee ID</span>
                                                    <label>{userDetail.employe_id ? userDetail.employe_id : "-"}</label>
                                                </li>

                                                <li>
                                                    <span>Joining Date</span>
                                                    {userDetail.doj && isNaN(Date.parse(userDetail.doj)) === false ?
                                                        <label>{new Date(userDetail.doj).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>

                                                <li>
                                                    <span>Anniversary</span>
                                                    {userDetail.anniversary_date && isNaN(Date.parse(userDetail.anniversary_date)) === false ?
                                                        <label>{new Date(userDetail.anniversary_date).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='d-flex  p-sm-t'>
                                        <div className='role-detail-preview preview-basic-details'>
                                            {/* <div >
                                                <h3 style={{ display: "flex" }}>Bank Details
                                                    {bankList.length > 0 && showEdit &&
                                                        <span onClick={() => showDetail(2)} className="verify-txt">Verify</span>
                                                    }</h3>

                                            </div> */}
                                            <ul>
                                                <li>
                                                    <span>Bank Name</span>
                                                    {bankList.length > 0 ?
                                                        <label>{bankList[0].label}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>


                                                <li>
                                                    <span>Branch</span>
                                                    {userDetail.bank_response && userDetail.bank_response.branch_name ?
                                                        <label>{userDetail.bank_response.branch_name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>

                                                <li>
                                                    <span>Account Number</span>
                                                    {userDetail.bank_response && userDetail.bank_response.account_number ?
                                                        <label>{userDetail.bank_response.account_number}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>

                                                <li>
                                                    <span>Beneficiary Name</span>
                                                    {userDetail.bank_response && userDetail.bank_response.beneficiary_name ?
                                                        <label>{userDetail.bank_response.beneficiary_name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>

                                                {/* <li>
                                                    <span>NPWP</span>
                                                    {userDetail.bank_response && userDetail.bank_response.NPWP ?
                                                        <label>{userDetail.bank_response.NPWP}</label> :
                                                        <label>-</label>
                                                    }
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='d-flex  p-sm-t'>
                                        <div className='role-detail-preview preview-basic-details'>
                                            <h3>Other Details</h3>
                                            <ul>
                                                <li>
                                                    <span>DOB</span>
                                                    {userDetail.dob && isNaN(Date.parse(userDetail.dob)) === false ?
                                                        <label>{new Date(userDetail.dob).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>State</span>
                                                    <label>{stateList.findIndex(e => e.id === userDetail.user_state) > -1 ? stateList.filter(e => e.id === userDetail.user_state)[0].label : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>City</span>
                                                    <label>{cityList.findIndex(e => e.id === userDetail.user_city) > -1 ? cityList.filter(e => e.id === userDetail.user_city)[0].label : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>Locality</span>
                                                    <label>{ userDetail.user_location ? userDetail.user_location : "-"}</label>
                                                </li>
                                               {/*  <li>
                                                    <span>Locality</span>
                                                    <label>{localityList.findIndex(e => e.id === userDetail.user_location) > -1 ? localityList.filter(e => e.id === userDetail.user_location)[0].label : "-"}</label>
                                                </li> */}
                                                {validationForFields.current_occupation.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Current Occupation</span>
                                                        <label>{BasicDetailConfig.current_occupation.findIndex(e => e.value === userDetail.current_occupation_id) > -1 ? BasicDetailConfig.current_occupation.filter(e => e.value === userDetail.current_occupation_id)[0].label : "-"}</label>
                                                    </li>
                                                }
                                                {validationForFields.company_name.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Company</span>
                                                        <label>{userDetail.company_name ? userDetail.company_name : "-"}</label>
                                                    </li>
                                                }
                                                {validationForFields.designation.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Designation</span>
                                                        <label>{BasicDetailConfig.designation.findIndex(e => e.value === userDetail.designation_id) > -1 ? BasicDetailConfig.designation.filter(e => e.value === userDetail.designation_id)[0].label : "-"}</label>
                                                    </li>
                                                }
                                                {/* {ConfigList.tagging_roles && validationForFields.tagging.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Tagging</span>
                                                        <label>{ConfigList.tagging_roles.findIndex(e => e.value === userDetail.tagging_id) > -1 ? ConfigList.tagging_roles.filter(e => e.value === userDetail.tagging_id)[0].label : "-"}</label>
                                                    </li>
                                                } */}
                                                {ConfigList.business_line &&
                                                    <li>
                                                        <span>Business Line</span>
                                                        <label>{ConfigList.business_line.findIndex(e => e.value === userDetail.business_line) > -1 ? ConfigList.business_line.filter(e => e.value === userDetail.business_line)[0].label : "-"}</label>
                                                    </li>
                                                }
                                                {validationForFields.source.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Source</span>
                                                        <label>{BasicDetailConfig.source.findIndex(e => e.value === userDetail.source_id) > -1 ? BasicDetailConfig.source.filter(e => e.value === userDetail.source_id)[0].label : "-"}</label>
                                                    </li>
                                                }
                                                <li>
                                                    <span>Postal Code</span>
                                                    <label>{userDetail.pincode ? userDetail.pincode : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>House/Plot</span>
                                                    <label>{userDetail.house_no ? userDetail.house_no : "-"}</label>
                                                </li>
                                                {/* <li>
                                                    <span>TIN</span>
                                                    <label>{userDetail.tin_id ? userDetail.tin_id : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>ARO/SO Assigned</span>
                                                    <label>{userDetail.aro_so_assigned ? userDetail.aro_so_assigned : "-"}</label>
                                                </li> */}
                                                {validationForFields.incentive_percentage.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Incentive%</span>
                                                        {userDetail.incentive_percentage > 0 ?
                                                            <label>{userDetail.incentive_percentage}</label> :
                                                            <label>-</label>
                                                        }
                                                    </li>
                                                }
                                                <li className='address-txt'>
                                                    <span>Address</span>
                                                    <label>{userDetail.address ? userDetail.address : "-"}</label>
                                                </li>
                                                {validationForFields.is_tax_booster_enable.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Tax Booster Enable</span>
                                                        <label>{userDetail.is_tax_booster_enable == true ? "Yes" : "No"}</label>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='d-flex  p-sm-t'>
                                        {/* {userDetail.password_text &&
                                            <div className='role-detail-preview preview-basic-details'>
                                                <h3>Password</h3>
                                                <ul>
                                                    <li>
                                                        <span>Password&nbsp;&nbsp;<i className="ic-remove_red_eyevisibility" onClick={() => setShowPassword(!showPassword)}></i></span>
                                                        <label>{passwordToShow}</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        } */}
                                        {validationForFields.referral_name.includes(userDetail.role_id) && ReferralName && ReferralName?.length > 0 && userDetail.referral_user_id > 0 &&
                                            <div className='role-detail-preview'>
                                                <h3>Reference</h3>
                                                <ul>
                                                    <li>
                                                        <span>Reference Name & ID</span>
                                                        <label>{ReferralName.findIndex(e => e.value === userDetail.referral_user_id) > -1 ? ReferralName.filter(e => e.id === userDetail.referral_user_id)[0].label : ""}</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>

                                    <div className='d-flex  p-sm-t m-xl-b p-md-b'>
                                        <div className='role-detail-preview preview-basic-details'>
                                            <h3>Additional Details</h3>
                                            <ul>
                                                {userDetail.created_date && isNaN(Date.parse(userDetail.created_date)) === false &&
                                                    <li>
                                                        <span>Created Date</span>
                                                        <label>{new Date(userDetail.created_date).toISOString().split('T')[0]}</label>
                                                    </li>
                                                }
                                                {userDetail.approval_date && isNaN(Date.parse(userDetail.approval_date)) === false &&
                                                    <li>
                                                        <span>Approval Date</span>
                                                        <label>{new Date(userDetail.approval_date).toISOString().split('T')[0]}</label>
                                                    </li>
                                                }
                                                <li>
                                                    <span>Onboarded From</span>
                                                    <label>{OnboardedFrom.findIndex(e => e.value === userDetail.user_source) > -1 ? OnboardedFrom.filter(e => e.value === userDetail.user_source)[0].label : "-"}</label>                                                    
                                                </li>
                                                <li>
                                                    <span>Onboarded By</span>
                                                    <label>{(userDetail.user_source && userDetail.user_source == 'admin') ? userDetail.created_by_admin_name : userDetail.created_by_user_name}</label>
                                                </li>
                                                <li>
                                                    <span>Resign Date 1</span>
                                                    {userDetail.resign_date_1 && isNaN(Date.parse(userDetail.resign_date_1)) === false ?
                                                        <label>{new Date(userDetail.resign_date_1).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>

                                                <li>
                                                    <span>Resign Date 2</span>
                                                    {userDetail.resign_date_2 && isNaN(Date.parse(userDetail.resign_date_2)) === false ?
                                                        <label>{new Date(userDetail.resign_date_2).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>Rejoin Date 1</span>
                                                    {userDetail.rejoin_date_1 && isNaN(Date.parse(userDetail.rejoin_date_1)) === false ?
                                                        <label>{new Date(userDetail.rejoin_date_1).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>Rejoin Date 2</span>
                                                    {userDetail.rejoin_date_2 && isNaN(Date.parse(userDetail.rejoin_date_2)) === false ?
                                                        <label>{new Date(userDetail.rejoin_date_2).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>Warning Date 1</span>
                                                    {userDetail.warning_date_1 && isNaN(Date.parse(userDetail.warning_date_1)) === false ?
                                                        <label>{new Date(userDetail.warning_date_1).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>Warning Date 2</span>
                                                    {userDetail.warning_date_2 && isNaN(Date.parse(userDetail.warning_date_2)) === false ?
                                                        <label>{new Date(userDetail.warning_date_2).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li className='address-txt'>
                                                    <span>Comments</span>
                                                    <label>{userDetail.comments ? userDetail.comments : "-"}</label>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && show_doc && userDetail.document_response && userDetail.document_response?.length > 0 &&
                                        < div className='top-heading d-flex'>
                                            <h2>Documents</h2>
                                             {showEdit && 
                                            <a onClick={() => handleEdit("/edit-user/documents?id=" + user_id)} className='edit-opt'>Edit</a>
                                            }
                                        </div>
                                    }

                                    <div className='d-flex'>
                                        <div className='role-detail-preview doc-preview-details'>

                                            <div className='upload-document-outer'>
                                                {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && userDetail.document_response && userDetail.document_response?.length > 0 && userDetail.document_response.map((data, index) => (
                                                    <div key={index}>
                                                        {data.doc_url !== "" ?
                                                            <div className="img-bx-select-opt img-doc-preview">
                                                                <div className="image-bx">
                                                                    <img onClick={() => { showDetail(index) }} src={data.doc_url ? data.doc_url : ""} className="" alt="No Document" />
                                                                </div>
                                                                <span className='document-name'>{ConfigList.document.filter(e => parseInt(e.id) === parseInt(data.document_id))[0].doc_title}</span>
                                                            </div> :
                                                            null}
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>

                                    {/* {esignApprovedHTML?.length || esignUserDoc?.length ? 
                                    <>
                                        <div className='top-heading d-flex'>
                                            <h2>Esign Agreement</h2>
                                        </div>
                                        
                                        {esignUserDoc?.length ? (
                                            <div className='d-flex' style={{ marginBottom: "15px" }}>
                                                <div className='role-detail-preview'>
                                                    {esignUserDoc.map((ele) => {
                                                        return (
                                                            <div className='row' style={{ marginBottom: "5px" }}>
                                                                <label class="col-sm-12">{`Esign Doc\t:\t`}<a onClick={() => { handleDownload(ele) }} title="Document" rel="noopener noreferrer">View ({DateFormat(ele.esign_approval_date, "yyyy-mm-dd hh:MM TT")})</a></label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ) : null}

                                        {esignApprovedHTML?.length ? (
                                            <div className='d-flex' style={{ marginBottom: "15px" }}>
                                                <div className='role-detail-preview'>
                                                    {esignApprovedHTML.map((ele) => {
                                                        let encodedID = Buffer.from(`${ele.id}`).toString('base64');
                                                        return (
                                                            <div className='row' style={{ marginBottom: "5px" }}>
                                                                <label class="col-sm-12">{`Esign Link\t:\t`}<a href={`${ESIGN_BASE_URL}?id=${encodedID}`} title="Document" target="_blank" rel="noreferrer">View ({DateFormat(ele.created_date, "yyyy-mm-dd hh:MM TT")})</a></label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ) : null}

                                    </> : null} */}

                                    <div className="t-right text-right">
                                        <button className='btn-primary' onClick={handleBack.bind(this)}>
                                            Back
                                        </button>
                                    </div>

                                    <div className="vechile-document-popup model-popup-outer verify-doc-detail-popup">
                                        <Modal show={show_image_detail} handleClose={() => hideDetail()} >
                                            <div className="modal-header">
                                                <h2>Verify Details with Documents</h2>
                                                {/* <span className='subheading'>Bank Statement</span> */}
                                            </div>
                                            <div className="modal-body">
                                                <div className='slider-left-sec'>
                                                    {userDetail && userDetail.document_response &&
                                                        <SimpleSlider ConfigList={ConfigList} errors={errors} image_width={image_width} rotated_angle={rotated_angle} activeIndex={activeIndex} changeSlide={changeSlide} doc={userDetail.document_response} />
                                                    }
                                                    <div className='modal-footer'>
                                                        <div className="image-opt">
                                                            {userDetail && userDetail.document_response && userDetail.document_response?.length > 0 &&
                                                                <ul>
                                                                    {/* <li>
                                                                        <a onClick={() => handleRemove()}>
                                                                            <i className="ic-delete-icn"></i>
                                                                        </a>
                                                                    </li> */}
                                                                    <li>
                                                                        <a onClick={handleRotate}>
                                                                            <i className="ic-refresh"></i>
                                                                        </a>
                                                                    </li>
                                                                    {/* <li>
                                                                        <a >
                                                                            <i className="ic-fullscreen"></i>
                                                                        </a>
                                                                    </li> */}
                                                                    <li>
                                                                        <a onClick={zoomIn}>
                                                                            <i className="ic-add 1"></i>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={zoomOut}>
                                                                            <i className="ic-remove"></i>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && userDetail.document_response?.length > 0 &&
                                                                <input
                                                                    type="file"
                                                                    name={ConfigList.document.filter(e => parseInt(e.id) === parseInt(userDetail.document_response[activeIndex].document_id))[0].doc_title}
                                                                    accept="image/*"
                                                                    id={"upload-image-doc_" + activeIndex}
                                                                    className="upload"
                                                                    style={{ display: 'none' }}
                                                                    onChange={handleChangeImage}
                                                                />
                                                            }
                                                            <button onClick={() => handleReUpload("")} className="btn-line btn-re-upload">Re-upload</button>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="slider-right-sec">

                                                    {(activeIndex === 0 || activeIndex === 3) &&
                                                        <fieldset className="">
                                                            <div className="material">
                                                                <input onBlur={checkExisting} type="number" name="id_number" id="id_number" label="ID Number" placeholder=" " value={userDetail.id_number} onChange={handleInputChange.bind(null, 'id_number', 'text')} required={true} className="form-input" />
                                                                <label data-label="ID Number*" className="form-label"></label>
                                                                {
                                                                    (errors && errors.id_number) ? <span className="error show">{errors.id_number}</span> : ''
                                                                }
                                                            </div>
                                                        </fieldset>
                                                    }

                                                    {(activeIndex === 1 || activeIndex === 2) &&
                                                        <div>
                                                            <fieldset className="single-select ">
                                                                {bankOption && bankOption.length > 0 &&
                                                                    <div className="material">
                                                                        <Select components={{ ValueContainer: CustomValueContainer }}
                                                                            options={bankOption}
                                                                            placeholder="Bank Name*"
                                                                            value={bankOption.filter((e) => e.id === userDetail.bank_response.sfa_bank_id)}
                                                                            className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            onChange={handleInputChange.bind(null, 'sfa_bank_id', "select")}
                                                                        />
                                                                        {
                                                                            (errors && errors.sfa_bank_id) ? <span className="error show">{errors.sfa_bank_id}</span> : ''
                                                                        }
                                                                    </div>
                                                                }
                                                            </fieldset>
                                                            {userDetail.bank_response &&
                                                                <fieldset className="">
                                                                    <div className="material">
                                                                        <InputField type="text" error={(errors.branch_name) ? errors.branch_name : ''} name="branch_name" id="branch_name" label="Branch" placeholder=" " value={userDetail.bank_response.branch_name} onChange={handleInputChange.bind(null, 'branch_name', 'text')} required={true} />
                                                                        <label data-label="Branch*" className="form-label"></label>
                                                                    </div>
                                                                </fieldset>
                                                            }
                                                            {userDetail.bank_response &&
                                                                <fieldset className="">
                                                                    <div className="material">
                                                                        <InputField type="text" error={(errors.beneficiary_name) ? errors.beneficiary_name : ''} name="beneficiary_name" id="beneficiary_name" label="Beneficiary Name" placeholder=" " value={userDetail.bank_response.beneficiary_name} onChange={handleInputChange.bind(null, 'beneficiary_name', 'text')} required={true} />
                                                                        <label data-label="Beneficiary Name*" className="form-label"></label>
                                                                    </div>
                                                                </fieldset>
                                                            }
                                                            {userDetail.bank_response &&
                                                                <fieldset className="">
                                                                    <div className="material">
                                                                        <input onBlur={checkExisting} type="password" name="account_number" id="account_number" label="Account Number" placeholder=" " value={userDetail.bank_response.account_number} onChange={handleInputChange.bind(null, 'account_number', 'text')} required={true} className="form-input" /* maxLength={account_length} */ RestrictOnPaste={1} RestrictOnCopy={1} />
                                                                        <label data-label="Account Number*" className="form-label"></label>
                                                                        {
                                                                            (errors && errors.account_number) ? <span className="error show">{errors.account_number}</span> : ''
                                                                        }
                                                                    </div>
                                                                </fieldset>
                                                            }
                                                            {userDetail.bank_response &&
                                                                <fieldset className="">
                                                                    <div className="material">
                                                                        <input onBlur={checkExisting} type="number" name="confirm_account_number" id="confirm_account_number" label="Confirm Account Number" placeholder=" " value={userDetail.bank_response.confirm_account_number} onChange={handleInputChange.bind(null, 'confirm_account_number', 'text')} required={true} className="form-input" /* maxLength={account_length} */ RestrictOnPaste={1} RestrictOnCopy={1} />
                                                                        <label data-label="Confirm Account Number*" className="form-label"></label>
                                                                        {
                                                                            (errors && errors.confirm_account_number) ? <span className="error show">{errors.confirm_account_number}</span> : ''
                                                                        }
                                                                    </div>
                                                                </fieldset>
                                                            }
                                                            {/* {userDetail.bank_response &&
                                                                <fieldset className="">
                                                                    <div className="material">
                                                                        <NumberFormat
                                                                            className="form-input"
                                                                            format="##.###.###.#-###.###"
                                                                            mask="_"
                                                                            id="NPWP"
                                                                            name="NPWP"
                                                                            label="NPWP"
                                                                            placeholder="NPWP"
                                                                            value={userDetail.bank_response.NPWP || ''}
                                                                            onBlur={checkExisting}
                                                                            onValueChange={handleInputChange.bind(null, 'NPWP', 'number')}
                                                                        />
                                                                        <label data-label="NPWP" className="form-label"></label>
                                                                        {
                                                                            (errors && errors.NPWP) ? <span className="error show">{errors.NPWP}</span> : ''
                                                                        }
                                                                    </div>
                                                                </fieldset>
                                                            } */}
                                                        </div>
                                                    }

                                                    <button className='btn-primary' onClick={() => handleUpdate()}>
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"second"}>
                                {showMappedRegions ? <ViewMappedRegions /> : null}
                            </Tab.Pane>
                            <Tab.Pane eventKey={"third"}>
                                {showReportees ? <ViewReportees /> : null}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
                <div className="model-popup-outer role-history-popup">
                    <Modal show={UserHistoryPopup} handleClose={hideModalUserHistoryPopup} >
                        <div className="modal-header">
                            <h2>User Timeline</h2>
                        </div>

                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                                <div className="tab-list">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Role History</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Account History</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="third">Vendor History</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">Progression History</Nav.Link>
                                    </Nav.Item> */}
                                </div>
                            </Nav>
                            <div className='modal-body' >
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className='data-table'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Created On</th>
                                                        <th>Role</th>
                                                        {/* <th>Tagging</th> */}
                                                        {/* <th>Business Line</th> */}
                                                        <th>Status</th>
                                                        <th>Created By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {UserRoleHistoryList && UserRoleHistoryList.length === 0 ? <tr><td align="center" colSpan="8"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                                                    {
                                                        UserRoleHistoryList && UserRoleHistoryList.length !== 0 && UserRoleHistoryList.map((userhistorydata, k) => (
                                                            <tr key={k} >
                                                                <td><span>{DateFormat(new Date(userhistorydata.created_date), 'dd mmm, yyyy')}</span></td>
                                                                <td>{userhistorydata.role_name}</td>
                                                                {/* <td>{userhistorydata.tagging_name}</td> */}
                                                                {/* <td>{userhistorydata.business_line}</td> */}
                                                                <td>{userhistorydata.user_status_name}</td>
                                                                <td>{(userhistorydata.action_via == 'admin') ? userhistorydata.display_name : userhistorydata.created_by}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className='data-table'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Created On</th>
                                                        <th>Bank Name</th>
                                                        <th>Bank Branch</th>
                                                        <th>Account Number</th>
                                                        <th>Beneficiary Name</th>
                                                        {/* <th>NPWP</th> */}
                                                        <th>Created By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {UserAccountHistoryList && UserAccountHistoryList.length == 0 ? <tr><td align="center" colSpan="6"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                                                    {
                                                        UserAccountHistoryList && UserAccountHistoryList.length != 0 && UserAccountHistoryList.map((useraccountdata, k) => (
                                                            <tr key={k} >
                                                                <td><span>{DateFormat(new Date(useraccountdata.created_date), 'dd mmm, yyyy')}</span></td>
                                                                <td>{useraccountdata.bank_name}</td>
                                                                <td>{useraccountdata.branch_name}</td>
                                                                <td>{useraccountdata.account_number}</td>
                                                                <td>{useraccountdata.beneficiary_name}</td>
                                                                {/* <td>{useraccountdata.npwp}</td> */}
                                                                <td>{(useraccountdata.action_via == 'admin') ? useraccountdata.display_name : useraccountdata.created_by}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div className='data-table'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Created On</th>
                                                        <th>Type</th>
                                                        <th>Status</th>
                                                        <th>Vendor ID</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {VendorHistoryList && VendorHistoryList.length == 0 ? <tr><td align="center" colSpan="4"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                                                    {
                                                        VendorHistoryList && VendorHistoryList.length != 0 && VendorHistoryList.map((uservendordata, k) => (
                                                            <tr key={k} >
                                                                <td><span>{DateFormat(new Date(uservendordata.created_date), 'dd mmm, yyyy')}</span></td>
                                                                <td>{uservendordata.show_type}</td>
                                                                <td>{uservendordata.show_response_status}</td>
                                                                <td>{uservendordata.vendor_id}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <div className='data-table'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Date & Time</th>
                                                        {/* <th>Current Role/Tagging</th> */}
                                                        <th>Recommended Role/Tagging</th>
                                                        <th>Recommended By</th>
                                                        <th>Comments</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ProgressionHistoryList && ProgressionHistoryList.length == 0 ? <tr><td align="center" colSpan="4"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                                                    {
                                                        ProgressionHistoryList && ProgressionHistoryList.length != 0 && ProgressionHistoryList.map((userprogressiondata, k) => (
                                                            <tr key={k} >
                                                                <td><span>{DateFormat(new Date(userprogressiondata.created_date), 'dd mmm, yyyy h:MM TT')}</span></td>
                                                                {/* <td>{(userprogressiondata.current_role ? userprogressiondata.current_role : "") + "/" + (userprogressiondata.current_tagging ? userprogressiondata.current_tagging : "")}</td> */}
                                                                <td>{(userprogressiondata.recommended_role ? userprogressiondata.recommended_role : "") + "/" + (userprogressiondata.recommended_tagging ? userprogressiondata.recommended_tagging : "")}</td>
                                                                <td style={{ textTransform: "capitalize" }}>{userprogressiondata.recommended_by ? userprogressiondata.recommended_by : ""}</td>
                                                                <td>{userprogressiondata.comment ? <Tooltip
                                                                    content={(
                                                                        <div className="controlled-example tooltip-main">
                                                                            <div className="tooltip-data">
                                                                                <h3 className="subheading">{userprogressiondata.comment}</h3>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    tagName="span"
                                                                    direction="down"
                                                                    className="tootip-outer-refinance"
                                                                    forceDirection
                                                                >
                                                                    <span>{(userprogressiondata.comment) ? (userprogressiondata.comment.length > 10) ? userprogressiondata.comment.substr(0, 10) + "..." : userprogressiondata.comment : ''}</span>
                                                                </Tooltip> : ""}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>

                        </Tab.Container>
                    </Modal>

                </div>
            </div>
        </React.Fragment >
    )
}
const Modal = ({ handleClose, show, children, style = {} }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main' style={style}>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};
export default ViewUser;