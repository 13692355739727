import React, { Component } from 'react';
import { connect } from 'react-redux';
import secureStorage from './../../config/encrypt';
import ls, {get,set} from "local-storage";
import { MasterService } from '../../services';
import { toast } from 'react-toastify';

class Logout extends Component{
    componentDidMount() {
        ls.set('logged_user_id',0);
        ls.set('logged_data',{});        
        secureStorage.removeItem('loginUserInfo');
        this.props.updateStateIntoRedux('LOGOUT_USER', '');  
        MasterService.post('/sfa/user/logout', {}).then(function (response) {
        }).catch(function (error) {
            toast.error(error);
        });
        this.props.history.push("/login")
    }
    render(){       
        return('');
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({type:actionType, componentState: stateData})
    }
  }

export default connect(null, mapDispatchToProps)(Logout);