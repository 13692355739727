import React, { useState, Component, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { invalidNumberValues } from '../../config/constant';

const AppsSubmittedCard = (props) => {

    useEffect(() => {
    }, [props.element.user_level_tagging, props.tempErrors, props.config])



    const handleChangeSelect = (option, key, index, index1) => {
        let temp_user_level_tagging = props.element.user_level_tagging
        temp_user_level_tagging[index1][key] = option
        props.onChange("user_level_tagging", temp_user_level_tagging, index)
    };

    const handleChange = (event, name, index, index1) => {
        let { value } = event.target
        let temp_user_level_tagging = props.element.user_level_tagging
        temp_user_level_tagging[index1][name] = value
        if ((name === "m2_lead_count" || name === "m1_lead_count")) {
            if (value <= 100) {
                props.onChange("user_level_tagging", temp_user_level_tagging, index)
            }
        } else {
            props.onChange("user_level_tagging", temp_user_level_tagging, index)
        }

    };

    const onKeyDown = (event) => {
        if (invalidNumberValues.includes(event.key)) {
          event.preventDefault();
        }
      }

    const { element, tempErrors, index, config } = props
    return (
        <React.Fragment>
            <div className='app-submit-outer add-incentive-table Progression-table-outer'>

                <table>
                    <thead>
                        <tr>
                            <th>User Level</th>
                            <th>Operator</th>
                            <th>M-2</th>
                            <th>Operator</th>
                            <th>M-1</th>
                        </tr>
                    </thead>
                    <tbody>
                        {element.user_level_tagging && element.user_level_tagging.map((data, index1) => (
                            <tr>
                                {data.label && <td>{data.label}</td>}
                                <td><fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            value={data.operator2 && config.operator.findIndex(({ value }) => value == data.operator2.value) > -1 ? config.operator.filter(({ value }) => value == data.operator2.value) : null}
                                            onChange={(e) => handleChangeSelect(e, 'operator2', index, index1)}
                                            options={config.operator}
                                            isDisabled={element.is_expired}
                                            placeholder="Operator*"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                        />

                                        {
                                            (tempErrors && tempErrors["operator2_" + index + "_" + index1]) ? <span className="error show">{tempErrors["operator2_" + index + "_" + index1]}</span> : ''
                                        }

                                    </div>
                                </fieldset>
                                </td>

                                <td>
                                    <fieldset>
                                        <div className="material">
                                            <input type="number" onKeyDown={(e)=>onKeyDown(e)} placeholder=" " name={"m2_lead_count" + index} onChange={(e) => handleChange(e, 'm2_lead_count', index, index1)} className="form-input" value={data.m2_lead_count} disabled={element.is_expired || false} />
                                        </div>
                                        {
                                            (tempErrors && tempErrors["M2_" + index + "_" + index1]) ? <span className="error show">{tempErrors["m2_lead_count_" + index + "_" + index1]}</span> : ''
                                        }
                                    </fieldset>
                                </td>

                                <td><fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            value={data.operator1 && config.operator.findIndex(({ value }) => value === data.operator1.value) > -1 ? config.operator.filter(({ value }) => value === data.operator1.value) : null}
                                            onChange={(e) => handleChangeSelect(e, 'operator1', index, index1)}
                                            options={config.operator}
                                            isDisabled={element.is_expired}
                                            placeholder="Operator*"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                        />
                                        {
                                            (tempErrors && tempErrors["operator1_" + index + "_" + index1]) ? <span className="error show">{tempErrors["operator1_" + index + "_" + index1]}</span> : ''
                                        }

                                    </div>
                                </fieldset>
                                </td>

                                <td>
                                    <fieldset>
                                        <div className="material">
                                            <input type="number" onKeyDown={(e)=>onKeyDown(e)} placeholder=" " name={"m1_lead_count" + index} onChange={(e) => handleChange(e, 'm1_lead_count', index, index1)} className="form-input" value={data.m1_lead_count} disabled={element.is_expired || false} />
                                        </div>
                                        {
                                            (tempErrors && tempErrors["m1_lead_count_" + index + "_" + index1]) ? <span className="error show">{tempErrors["m1_lead_count_" + index + "_" + index1]}</span> : ''
                                        }
                                    </fieldset>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}
export default AppsSubmittedCard;
