import MasterService from './MasterService';


export const UserService = {
    ...MasterService,
    
    login(params){
        return this.post('/user/login',params)
    },
    loginCaptcha(){
        return this.get('/user/getCaptchaImage')
    }

}